import _ from "lodash";
import {
  PORTAL_CASELOAD,
  PORTAL_CASELOAD_ENDDATE,
  PORTAL_DETAILS,
  FETCH_MISSIONS,
  PORTAL_LIST_CONTRACTS,
  PORTAL_ORGADMIN_CASELOAD,
  PORTAL_ORGADMIN_CASELOAD_ENDDATE,
  PORTAL_ORGADMIN_CASELOAD_GLOBAL,
  PORTAL_SUPPLY_DATA,
  PORTAL_SELECT_CONTRACT,
  PORTAL_SELECT_KEYWORKER,
  PORTAL_SELECT_KEYWORKERS,
  PORTAL_DESELECT_KEYWORKER,
  PORTAL_SELECT_PROVIDER,
  FETCH_INTERVENTIONS,
  EMPTY_CANDIDATES_SELECTION,
  PORTAL_DASHBOARD_CASELOAD,
  SET_RECENTLY_VIEWED,
  PORTAL_SUPPLY_LOW,
  PORTAL_LOCATION_LIST,
  PORTAL_ADZUNA_CATEGORY,
  PORTAL_ADZUNA_HISTOGRAM_CATEGORY,
  PORTAL_ADZUNA_HISTORICAL_CATEGORY,
  PORTAL_ADZUNA_REGION_DATA,
  UPDATE_BULK_UPLOAD_PROGRESS,
  SET_BULK_EMAIL_TEMPLATE,
  SET_ROLES,
  SET_DEPARTMENTS,
  SET_DEMOGRAPHICS,
  SET_LINEMANAGERS,
  SET_UNVERIFIED,
  SET_HELP_VIDEOS
} from "../actions/types";

import { Logger } from "aws-amplify";
const logger = new Logger("PORTAL DETAILS.REDUCER", "DEBUG");

function mergeObjectsWithDivision(obj, obj1){
  return _.mergeWith(obj, obj1, (objValue, srcValue, key) => {
    
    if (_.isNumber(objValue)) {
        return ((objValue + srcValue ).toFixed(1) / 2)
    };
    
  });
};
function mergeObjectsWithSum(obj, obj1){
  return _.mergeWith(obj, obj1, (objValue, srcValue, key) => {
    
    if (_.isNumber(objValue)) {
      if (key === "value"){
        return objValue + srcValue;
      } else {
        return objValue + srcValue;
      }
    };
    
  });
};


//this takes the main caseload/insights object(plus engagement data in some bits?) and reformats it for the graphs on the dashboard
function caseLoads(caseload, state) {
  if (caseload) { 
    var caseloaddata = [];  //this is for the "Overall Caseload" graph
    caseloaddata.push({
      name: "Invited",
      value: caseload.all.invited,
      fill: "#84dc20",
    });

    caseloaddata.push({
      name: "Accessed",
      value: caseload.all.accessed,
      fill: "#ff6505",
    });
    caseloaddata.push({
      name: "Initial Assessment Complete",
      value: caseload.all.iaCompleted,
      fill: "#4fc3f7",
    });
    caseloaddata.push({
      name: "Reassessment Issued",
      value: caseload.all.reassessmentIssued,
      fill: "#fdd835",
    });
    caseloaddata.push({
      name: "Reassessment Completed",
      value: caseload.all.reassessmentCompleted,
      fill: "#c2185b",
    });
    caseloaddata = caseloaddata.reverse();
  } else {
    if (state.caseload && state.caseload.orgadminCaseload)
      return {
        ...state,
        caseload: {
          orgadminCaseload: state.caseload.orgadminCaseload,
        },
        dashboard: {},
      };
    else {
      return {
        ...state,
        caseload: {},
        dashboard: {},
      };
    }
  }

  var dashboard = {
    caseload: caseloaddata, //"Overall Caseload" graph
  };

  if (caseload && caseload.aggregated) {
    dashboard.aggregatedGraphData = [   //aggregatedGraphData is for three graphs: Average Work Styles Initial vs. Latest Assessments (%) + Average Work Style Distance Travelled (%) + Average Initial Work Styles
      {
        name: "Commitment",
        id: "COMM",
      },
      {
        name: "Responsibility",
        id: "RTF",
      },
      {
        name: "Adaptability",
        id: "ADY",
      },
      {
        name: "Complexity",
        id: "SPC",
      },
      {
        name: "Learning",
        id: "RLS",
      },
      {
        name: "Speed",
        id: "DMA",
      },
      {
        name: "Confidence",
        id: "CONF",
      },
      {
        name: "Control",
        id: "CONT",
      },
      {
        name: "Accuracy",
        id: "PSA",
      },
    ];

    dashboard.interestGraphData = [
      {
        name: "Crafter",
        id: "crafter",
      },
      {
        name: "Creator",
        id: "creator",
      },
      {
        name: "Doer",
        id: "doer",
      },
      {
        name: "Driver",
        id: "driver",
      },
      {
        name: "Facilitator",
        id: "facilitator",
      },
      {
        name: "Investigator",
        id: "investigator",
      },
    ];

    dashboard.aggregatedGraphData.forEach((item) => { // main bit for Average Work Styles Initial vs. Latest Assessments (%)  and Average Initial Work Styles
      item._latest =
        Math.round(
          parseFloat(caseload.aggregated.latestAssessment[item.id]) * 100 + //Initial vs. Latest
            Number.EPSILON
        ) / 100;

      item._initial =
        Math.round(
          parseFloat(caseload.aggregated.initialAssessment[item.id]) * 100 + //Initial vs. Latest
            Number.EPSILON
        ) / 100;

      item._onlyInitial =    //Average Initial Work Styles
        Math.round(
          parseFloat(caseload.aggregated.onlyInitialAssessment[item.id]) *
            100 +
            Number.EPSILON
        ) / 100;

      if (
        caseload.aggregated.latestAssessment[item.id] &&
        caseload.aggregated.initialAssessment[item.id]
      ) {
        item._dt =  // this bit for Average Work Style Distance Travelled (%)
          Math.round(
            ((caseload.aggregated.latestAssessment[item.id] /
              caseload.aggregated.initialAssessment[item.id]) *
              100 -
              100) *
              100 +
              Number.EPSILON
          ) / 100;
      } else {
        item._dt = 0;
      }
    });
    if (
      caseload.aggregated.latestAssessment.count === 0 &&
      caseload.aggregated.initialAssessment.count === 0 &&
      caseload.aggregated.onlyInitialAssessment.count === 0
    ) {
      dashboard.aggregatedGraphData = [];
    }

    var sum = 0;

    dashboard.interestGraphData.forEach((item) => {  // for Average Interests (%)
      sum += parseFloat(caseload.aggregated.interests[item.id]);
    });

    var nullInterests = false;
    dashboard.interestGraphData.forEach((item) => {
      var n =
        (parseFloat(caseload.aggregated.interests[item.id]) / sum) * 100;
      item.value = Math.round(n * 10 + Number.EPSILON) / 10;
      if (
        !_.isNumber(item.value) ||
        _.isNaN(item.value) ||
        _.isNull(item.value)
      ) {
        nullInterests = true;
      }
    });

    if (nullInterests) {
      dashboard.interestGraphData = [];
    }

    if (Object.keys(caseload.aggregated.locations).length) {  // pretty simple for Top 15 Candidate Locations,  where does this come from though??
      dashboard.locationGraph = [];
      for (let [key, value] of Object.entries(
        caseload.aggregated.locations
      )) {
        dashboard.locationGraph.push({
          name: key,
          value: value,
        });
      }
      dashboard.locationGraph = dashboard.locationGraph.sort(function (
        a,
        b
      ) {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });
      dashboard.locationGraph = dashboard.locationGraph.slice(-15);
    } else {
      dashboard.locationGraph = [];
    }


    //jobs and quals here are simple counts,  but where do they come from???

    //jobs
    dashboard.tierGraph = [];
    dashboard.occupationGraph = [];
    dashboard.jobsData = {};
    dashboard.occupationGraphAppliedJobs = [];
    dashboard.tierGraphAppliedJobs = [];
    dashboard.occupationGraphAppliedQuals = [];
    dashboard.tierGraphAppliedQuals = [];
    dashboard.occupationGraphSavedJobs = [];
    dashboard.tierGraphSavedJobs = [];
    dashboard.occupationGraphSavedQuals = [];
    dashboard.tierGraphSavedQuals = [];
    dashboard.engagementData = {};
    dashboard.previous_experience = false;
    dashboard.previous_experience_low = false;
    dashboard.previous_experience_high = false;
    dashboard.qualsData = {};
    dashboard.qualsOccupationGraph = [];
    dashboard.qualsTierGraph = [];
    //jobs
    if (Object.keys(caseload.aggregated.jobs).length) {
      const {
        jobsApplied = null,
        jobsViewed = null,
        jobsSaved = null,
      } = caseload.aggregated.jobs;

      dashboard.jobsData = {
        jobsApplied,
        jobsViewed,
        jobsSaved,
      };

      for (let [key, value] of Object.entries(caseload.aggregated.jobs)) {
        if (key.startsWith("occ_")) {
          dashboard.occupationGraph.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.tierGraph.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    }

    if (caseload.aggregated.jobs_applied && Object.keys(caseload.aggregated.jobs_applied).length) {
      
      caseload.aggregated.jobs_applied = _.omit(caseload.aggregated.jobs_applied, ["occ_undefined" ,"tier_Other"]);
      for (let [key, value] of Object.entries(caseload.aggregated.jobs_applied)) {
        if (key.startsWith("occ_")) {
          dashboard.occupationGraphAppliedJobs.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.tierGraphAppliedJobs.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    } else {
      dashboard.occupationGraphAppliedJobs = false;
      dashboard.tierGraphAppliedJobs = false;
    }

    if (caseload.aggregated.jobs_saved && Object.keys(caseload.aggregated.jobs_saved).length) {
      
      caseload.aggregated.jobs_saved = _.omit(caseload.aggregated.jobs_saved, ["occ_undefined" ,"tier_Other"]);
      for (let [key, value] of Object.entries(caseload.aggregated.jobs_saved)) {
        if (key.startsWith("occ_")) {
          dashboard.occupationGraphSavedJobs.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.tierGraphSavedJobs.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    } else {
      dashboard.tierGraphSavedJobs = false;
      dashboard.occupationGraphSavedJobs = false;
    }

    if (caseload.aggregated.quals_applied && Object.keys(caseload.aggregated.quals_applied).length) {
      
      caseload.aggregated.quals_applied = _.omit(caseload.aggregated.quals_applied, ["occ_undefined" ,"tier_Other"]);
      for (let [key, value] of Object.entries(caseload.aggregated.quals_applied)) {
        if (key.startsWith("occ_")) {
          dashboard.occupationGraphAppliedQuals.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.tierGraphAppliedQuals.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    } else {
      dashboard.tierGraphAppliedQuals = false;
      dashboard.occupationGraphAppliedQuals = false;
    }

    if (caseload.aggregated.quals_saved && Object.keys(caseload.aggregated.quals_saved).length) {
      
      caseload.aggregated.quals_saved = _.omit(caseload.aggregated.quals_saved, ["occ_undefined" ,"tier_Other"]);
      for (let [key, value] of Object.entries(caseload.aggregated.quals_saved)) {
        if (key.startsWith("occ_")) {
          dashboard.occupationGraphSavedQuals.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.tierGraphSavedQuals.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    } else {
      dashboard.tierGraphSavedQuals = false;
      dashboard.occupationGraphSavedQuals = false;
    }
    
    if (caseload.aggregated.previous_experience) {
      dashboard.previous_experience = caseload.aggregated.previous_experience;
    }

    if (caseload.aggregated.previous_experience_low) {
      dashboard.previous_experience_low = caseload.aggregated.previous_experience_low;
    }
    if (caseload.aggregated.previous_experience_high) {
      dashboard.previous_experience_high = caseload.aggregated.previous_experience_high;
    }

    //quals
    if (caseload.aggregated && caseload.aggregated.quals && Object.keys(caseload.aggregated.quals).length) {
      const {
        jobsApplied = null,
        jobsViewed = null,
        jobsSaved = null,
      } = caseload.aggregated.quals;

      dashboard.qualsData = {
        jobsApplied,
        jobsViewed,
        jobsSaved,
      };

      for (let [key, value] of Object.entries(caseload.aggregated.quals)) {
        if (key.startsWith("occ_")) {
          dashboard.qualsOccupationGraph.push({
            name: key.split("occ_")[1],
            value: value,
          });
        }
        if (key.startsWith("tier_")) {
          dashboard.qualsTierGraph.push({
            name: key.split("tier_")[1],
            value: value,
          });
        }
      }
    }


    dashboard.occupationGraph = dashboard.occupationGraph.sort(function (
      a,
      b
    ) {
      if (a.value > b.value) return 1;
      if (a.value < b.value) return -1;
      return 0;
    });

    dashboard.qualsOccupationGraph = dashboard.qualsOccupationGraph.sort(
      function (a, b) {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      }
    );

    if (Object.keys(caseload.aggregated.engagement).length) {
      const {
        numberOfSessions = null,
        totalMinutes = null,
      } = caseload.aggregated.engagement;

      dashboard.engagementData = {
        numberOfSessions,
        totalMinutes,
      };
    }
  }
  return {
    ...state,
    dashboard: dashboard,
  };
}


export default function (state = {}, action) {
  switch (action.type) {
    case PORTAL_DETAILS: {
      //console.error(state);

      var selectedContract = null;

      if (state.selectedContract) {
        selectedContract = state.selectedContract;
      }

      var _orgcontracts = [];
      var _providers = [];

      var {
        portaldetails: { data = {}, orgdetails = {}, orgcontracts, providers },
      } = action;
      

      const { contracts } = data;
      if (data && orgcontracts) {
        let userdata = data;

        if (contracts && contracts.length && !selectedContract) {
          contracts.sort();
          var first = contracts[0];
          selectedContract = _.find(orgcontracts, {
            contractID: first,
          });
        }

        if (userdata.role === "keyworker") {
          //remove the contracts what i dont have
          _.each(contracts, (item) => {
            let x = _.find(orgcontracts, {
              contractID: item,
            });
            if (x) {
              _orgcontracts.push(x);
            }
          });
        } else {
          _orgcontracts = orgcontracts;
          _providers = providers;
        }
      }

      const returnProps = {
        ...state,
        user: data,
        orgcontracts: _orgcontracts,
        selectedContract: selectedContract,
        providers: _providers,
        orgdetails: orgdetails
      };


      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case FETCH_INTERVENTIONS: {
      var only = [];
      action.interventions.forEach((item) => {
        if (item.type === 2) {
          only.push(item);
        }
      });

      const returnProps = {
        ...state,
        interventions: action.interventions,
        onlyInterventions: only,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case PORTAL_LIST_CONTRACTS: {
      return {
        ...state,
        contracts: action.contracts,
      };
    }

    case PORTAL_SELECT_CONTRACT: {
      const returnProps = {
        ...state,
        selectedContract: action.contract,
        missions: null,
        interventions: null,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case PORTAL_DESELECT_KEYWORKER: {
      return {
        ...state,
        currentKeyWorker: null,
      };
    }
    case PORTAL_SELECT_KEYWORKER: {
      return {
        ...state,
        currentKeyWorker: action.keyworker,
      };
    }
    case PORTAL_SELECT_KEYWORKERS: {
      return {
        ...state,
        currentKeyWorker: action.keyworker,
      };
    }
    case PORTAL_SELECT_PROVIDER:{
      return {
        ...state,
        selectedProvider: action.provider,
      };
    }
    case FETCH_MISSIONS: {
      _.each(action.missions, (item) => {
        if (item.dimension === "COMM") {
          item.dimensionText = "Commitment";
        }
        if (item.dimension === "RTF") {
          item.dimensionText = "Responsiveness";
        }
        if (item.dimension === "ADY") {
          item.dimensionText = "Adaptibility";
        }
        if (item.dimension === "SPC") {
          item.dimensionText = "Complexity";
        }
        if (item.dimension === "RLS") {
          item.dimensionText = "Learning";
        }
        if (item.dimension === "DMA") {
          item.dimensionText = "Speed";
        }
        if (item.dimension === "CONF") {
          item.dimensionText = "Confidence";
        }
        if (item.dimension === "CONT") {
          item.dimensionText = "Control";
        }
        if (item.dimension === "PSA") {
          item.dimensionText = "Accuracy";
        }
      });

      const returnProps = {
        ...state,
        missions: _.sortBy(action.missions, ["dimension", "id"]),
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case PORTAL_CASELOAD: {
      const {
        payload: { contractId, keyworkerId, ...caseloadData },
      } = action;

      const { caseload = {} } = state;

      const existingCaseloads = caseload[keyworkerId] || {};
      const newCaseload = Object.assign({}, existingCaseloads, {
        [contractId]: caseloadData,
      });

      return {
        ...state,
        caseload: { ...caseload, [keyworkerId]: newCaseload },
      };
    }
    case PORTAL_CASELOAD_ENDDATE: {
      return {
        ...state,
        keyWorkerEndDateCaseload: action.payload,
      };
    }
    case PORTAL_ORGADMIN_CASELOAD: {
      const { caseload: orgadminCaseload = { dashboard: {} } } = action;

      const { caseload = {} } = state;

      return {
        ...state,
        caseload: { ...caseload, orgadminCaseload },
      };
    }
    case PORTAL_ORGADMIN_CASELOAD_ENDDATE: {
      const { caseload: orgadminCaseload = { dashboard: {} } } = action;

      const { caseload = {} } = state;

      return {
        ...state,
        endDateCaseload: { ...caseload, orgadminCaseload },
      };
    }

    case PORTAL_ORGADMIN_CASELOAD_GLOBAL: {
      const data  = action.data;
      let dashboard = caseLoads(data);
      return {
        ...state,
        globalDashboard: dashboard.dashboard
      }
    }

    case PORTAL_LOCATION_LIST: {
      const data = action.data;
      return {
        ...state,
        locationList: data
      }
    }

    case PORTAL_SUPPLY_DATA : {
      const data = action.data;
      return {
        ...state,
        supplyData: data
      }
    };

    case PORTAL_SUPPLY_LOW : {
      const data = action.data;
      return {
        ...state,
        supplyDataLowLevel: data
      }
    };
    case PORTAL_ADZUNA_CATEGORY : {
      const data = action.data;
      let newCategory = {}
      data.forEach((item) => {
        newCategory[item.label] = item.tag
      })
      return {
        ...state,
        adzunaCategory: newCategory,
        // cityList: data.cityList
      }
    }
    case PORTAL_ADZUNA_REGION_DATA : {
      const data = action.data;
      return {
        ...state,
        adzunaRegional: data
      }
    };
    case PORTAL_ADZUNA_HISTOGRAM_CATEGORY : {
      const data = action.data;
      return {
        ...state,
        adzunaHistogram: data
      }
    };
    case PORTAL_ADZUNA_HISTORICAL_CATEGORY : {
      const data = action.data;
      return {
        ...state,
        adzunaHistorical: data
      }
    };

    case PORTAL_DASHBOARD_CASELOAD: {
      const { caseload } = action;
        //caseload is an obj not array wtf?
        if (_.isArray(caseload)){
          let dashboards = [];

          let combinedObject = {caseload: {}};
          if (caseload.length > 1){
          _.each(caseload, (index) =>{
            let {dashboard} = caseLoads(index.data, state);
            dashboards.push(dashboard);
            // since all propertys can either be a sum total or a sum average we need to manually merge each property
            let caseLoad = {caseload: dashboard.caseload};
            let aggregatedGraphData = {aggregatedGraphData: dashboard.aggregatedGraphData};
            let engagementData  = {engagementData: dashboard.engagementData};
            let locationGraph   = {locationGraph: dashboard.locationGraph};
            let interestGraphData = {interestGraphData: dashboard.interestGraphData};
            let occupationGraph   = {occupationGraph: dashboard.occupationGraph};
            let qualsTierGraph  = {qualsTierGraph: dashboard.qualsTierGraph};
            let tierGraph     = { tierGraph: dashboard.tierGraph};
            let qualsData     = {qualsData: dashboard.qualsData};
            let jobsData      = {jobsData: dashboard.jobsData};
            let qualsOccupationGraph = {qualsOccupationGraph: dashboard.qualsOccupationGraph};

            combinedObject = mergeObjectsWithSum(combinedObject, caseLoad);
            combinedObject = mergeObjectsWithDivision(combinedObject, aggregatedGraphData);
            combinedObject = mergeObjectsWithSum(combinedObject, engagementData);
            combinedObject = mergeObjectsWithSum(combinedObject, locationGraph);
            combinedObject = mergeObjectsWithDivision(combinedObject, interestGraphData);
            combinedObject = mergeObjectsWithSum(combinedObject, occupationGraph);
            combinedObject = mergeObjectsWithSum(combinedObject, qualsTierGraph);
            combinedObject = mergeObjectsWithSum(combinedObject, tierGraph);
            combinedObject = mergeObjectsWithSum(combinedObject, qualsData);
            combinedObject = mergeObjectsWithSum(combinedObject, jobsData);
            combinedObject = mergeObjectsWithSum(combinedObject, qualsOccupationGraph);
          })
          } else { 
            _.each(caseload, (index) =>{
              
              let {dashboard} = caseLoads(index.data, state);
              combinedObject = dashboard;
            });
          }
          return {
            ...state,
            dashboard: combinedObject,
          };
        
        } else {
          let {dashboard} = caseLoads(caseload, state);
          return {
            ...state,
            dashboard: dashboard,
          };
        }

      
    }

    case EMPTY_CANDIDATES_SELECTION: {
      return {
        ...state,
        selectedContract: null,
        currentKeyWorker: null,
      };
    }

    case SET_RECENTLY_VIEWED: {
      let d = action.data;
      if (d && d.Item && d.Item.users) {
        d = d.Item.users;
      } else {
        d = [];
      }
      return {
        ...state,
        recentlyViewedUsers: d
      }
    }

    case UPDATE_BULK_UPLOAD_PROGRESS:{
      return {
        ...state,
        bulkUploadProgressText: action.data
      }
    }

    case SET_BULK_EMAIL_TEMPLATE:{
      return {
        ...state,
        defaultBulkTemplate: action.data
      }
    }
    case SET_ROLES:{
      return {
        ...state,
        internalRoles: action.data ? action.data : [],
        unverifiedRoles: action.unverifiedRoles ? action.unverifiedRoles : [],
        unverifiedRolesLength: action.unverifiedRoles ? action.unverifiedRoles.length : 0
      }
    }
    case SET_DEPARTMENTS:{
      return {
        ...state,
        internalDepartments: action.data ? action.data : [],
        unverifiedDepartments: action.unverifiedDepartments ? action.unverifiedDepartments : [],
        unverifiedDepartmentsLength: action.unverifiedDepartments ? action.unverifiedDepartments.length : 0
      }
    }
    case SET_LINEMANAGERS:{
      return {
        ...state,
        internalLineManagers: action.data ? action.data : [],
        unverifiedLineManagers: action.unverifiedLineManagers ? action.unverifiedLineManagers : [],
        unverifiedLineManagersLength: action.unverifiedLineManagers ? action.unverifiedLineManagers.length : 0
      }
    }
    case SET_UNVERIFIED:{
      return {
        ...state,
        unverifiedRolesLength: action.data.unverifiedRolesLength ? action.data.unverifiedRolesLength : 0,
        unverifiedDepartmentsLength: action.data.unverifiedDepartmentsLength ? action.data.unverifiedDepartmentsLength : 0,
        unverifiedLineManagersLength: action.data.unverifiedLineManagersLength ? action.data.unverifiedLineManagersLength : 0
      }
    }
    case SET_DEMOGRAPHICS:{
      return {
        ...state,
        demographicsQuestions: action.data ? action.data : []
      }
    }
    case SET_HELP_VIDEOS:{
      return {
        ...state, 
        authenticatedVideos: action.data
      }
    }
    default:
      return state;
  }
}
