// Actions related to authentication
export const AUTH_USER = "AUTH_USER";
export const AUTH_MFA = "AUTH_MFA";
export const AUTH_NEW_PASSWORD_REQUIRED = "AUTH_NEW_PASSWORD_REQUIRED";
export const UNAUTH_USER = "UNAUTH_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_CONFIRM = "REGISTER_USER_CONFIRM";
export const REGISTER_MFA = "REGISTER_MFA";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_CONFIRM = "FORGOT_PASSWORD_CONFIRM";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERROR_CLEAR = "AUTH_ERROR_CLEAR";

//CANDIDATES
export const FETCH_CANDIDATES = "candidates/FETCH";
export const FETCH_CANDIDATE_DETAILS = "candidates/FETCH_CANDIDATE_DETAILS";
export const EMPTY_CANDIDATE_DETAILS = "candidates/EMPTY_CANDIDATE_DETAILS";
export const EMPTY_CANDIDATES = "candidates/EMPTY";
export const SET_NEW_SKILLS = "candidates/SET_NEW_SKILLS";
export const EMPTY_CANDIDATES_SELECTION =
  "candidates/CANDIDATE_PAGE_EMPTY_SELECTION";
export const FETCH_CANDIDATE_BACKPACK = "candidates/CANDIDATE_BACKPACK";
export const FETCH_CANDIDATE_MATCHES = "candidates/FETCH_CANDIDATE_MATCHES";
export const FETCH_CANDIDATE_SKILLS_INFO = "candidates/CANDIDATE_SKILLS_INFO";
export const FETCH_CANDIDATE_ENGAGEMENTS = "candidates/CANDIDATE_ENGAGEMENTS";
export const FETCH_CANDIDATE_JOB_MATCHES = "candidates/CANDIDATE_JOB_MATCHES";
//ADMIN /KEYWORKER
export const OPEN_KEYWORKER_DIALOG = "orgadmin/OPEN_KEYWORKER_DIALOG";
export const CLOSE_KEYWORKER_DIALOG = "orgadmin/CLOSE_KEYWORKER_DIALOG";
export const ADD_KEYWORKER_CONTRACT = "orgadmin/ADD_KEYWORKER_CONTRACT";

export const FETCH_PORTAL_USERS = `/orgadmin/PORTALUSERS`;

//PORTAL USER
export const PORTAL_DETAILS = "portal/DETAILS";
export const FETCH_INTERVENTIONS = "portal/FETCH_INTERVENTIONS";
export const FETCH_MISSIONS = "portal/GET_MISSIONS";
export const SET_RECENTLY_VIEWED = "portal/SET_RECENTLY_VIEWED";
export const PORTAL_LOCATION_LIST = "portal/PORTAL_LOCATION_LIST";
export const CUSTOMER_CHECK = "portal/CUSTOMER_CHECK";
export const SET_HELP_VIDEOS = "portal/SET_HELP_VIDEOS";

//EMPLOYER / VACANCIES
export const FETCH_VACANCIES = "vacancies/FETCH_VACANCIES";
export const ADD_VACANCY = "vacancies/ADD_VACANCY";
export const EDIT_VACANCY = "vacancies/EDIT_VACANCY";
export const FETCH_VACANCIES_CANDIDATES = "vacancies/FETCH_VACANCIES_CANDIDATES";
export const FIND_VACANCIES_STATUSES = "vacancies/FIND_VACANCIES_STATUSES";
export const SET_CANDIDATE_DETAILS = "vacancies/SET_CANDIDATE_DETAILS";
export const UPDATE_CANDIDATE_INFO = "vacancies/UPDATE_CANDIDATE_INFO";
export const SET_VACANCY_BY_ID  = "vacancies/SET_VACANCY_BY_ID";
export const ADD_SEARCH  = "vacancies/ADD_SEARCH";
export const FETCH_SEARCHES = "vacancies/FETCH_SEARCHES";
export const REMOVE_SEARCH = "vacancies/REMOVE_SEARCH";
export const EDIT_SEARCH = "vacancies/EDIT_SEARCH";
export const FETCH_SEARCH_CANDIDATES = "vacancies/FETCH_SEARCH_CANDIDATES";
export const FETCH_SEARCH_SHORTLIST = "vacancies/FETCH_SEARCH_SHORTLIST";
export const UPDATE_SEARCH_SHORTLIST = "vacancies/UPDATE_SEARCH_SHORTLIST";
export const FILTER_SEARCH_CANDIDATES = "vacancies/FILTER_SEARCH_CANDIDATES";
export const FETCH_BOT_TEMPLATE = "vacancies/FETCH_BOT_TEMPLATE";
export const EDIT_TEMPLATE = "vacancies/EDIT_TEMPLATE";
export const ADD_TEMPLATE = "vacancies/ADD_TEMPLATE";
export const REMOVE_TEMPLATE = "vacancies/REMOVE_TEMPLATE";
export const UPDATE_VIEWED_CANDIDATES = "vacancies/UPDATE_VIEWED_CANDIDATES";
export const REMOVE_VACANCY = "vacancies/REMOVE_VACANCY";
export const UPDATE_SNACKBAR = "vacancies/UPDATE_SNACKBAR";
export const FETCH_VACANCIES_CANDIDATES_MONSTER = "vacancies/FETCH_VACANCIES_CANDIDATES_MONSTER";
export const REMOVE_VACANCIES_CANDIDATES = "vacancies/REMOVE_VACANCIES_CANDIDATES";
export const GET_VIEW_PREFS = "vacancies/GET_VIEW_PREFS";

//QUALIFICATIONS 
export const UPDATE_QUALIFICATION = "qualification/UPDATE_QUALIFICATION";
export const LOADING_QUALIFICATION = "qualification/LOADING";
export const ADD_QUALIFICATION = "qualification/ADD";
export const FETCH_QUALIFICATIONS = "qualification/FETCH_QUALIFICATIONS";

export const PORTAL_SELECT_CONTRACT = "portal/SELECT_CONTRACT";
export const PORTAL_LIST_CONTRACTS = "portal/LIST_CONTRACTS";
export const LIST_KEYWORKER_CONTRACTS = "portal/LIST_KEYWORKER_CONTRACTS";

export const PORTAL_SELECT_KEYWORKER = "portal/SELECT_KEYWORKER";
export const PORTAL_SELECT_KEYWORKERS = "portal/SELECT_KEYWORKERS";
export const PORTAL_DESELECT_KEYWORKER = "portal/PORTAL_DESELECT_KEYWORKER";

export const PORTAL_SELECT_PROVIDER = "portal/PORTAL_SELECT_PROVIDER";

export const PORTAL_CASELOAD = `portal/CASELOAD`;
export const PORTAL_CASELOAD_ENDDATE = `portal/CASELOAD/ENDDATE`;
export const PORTAL_ORGADMIN_CASELOAD = `orgadmin/CASELOAD`;
export const PORTAL_ORGADMIN_CASELOAD_GLOBAL = "orgadmin/CASELOAD/GLOBAL";
export const PORTAL_ORGADMIN_CASELOAD_ENDDATE = `orgadmin/CASELOAD/ENDDATE`;
export const PORTAL_DASHBOARD_CASELOAD = `portal/DASHBOARD_CASELOAD`;
export const PORTAL_DASHBOARD_CASELOADS = `portal/DASHBOARD_CASELOADS`;
export const PORTAL_SUPPLY_DATA = `portal/PORTAL_SUPPLY_DATA`;
export const PORTAL_SUPPLY_LOW  = `portal/PORTAL_SUPPLY_LOW`;
export const PORTAL_ADZUNA_CATEGORY = `portal/PORTAL_ADZUNA_CATEGORY`;
export const PORTAL_ADZUNA_HISTORICAL_CATEGORY = `portal/PORTAL_ADZUNA_HISTORICAL_CATEGORY`;
export const PORTAL_ADZUNA_HISTOGRAM_CATEGORY = `portal/PORTAL_ADZUNA_HISTOGRAM_CATEGORY`;
export const PORTAL_ADZUNA_REGION_DATA = `portal/PORTAL_ADZUNA_REGION_DATA`;

//RESET
export const RESET_APP = "RESET_APP";

//SUPER ADMIN
export const SA_FETCH_ORGS = "sa/FETCH_ORGS";
export const SA_FETCH_ORGADMINS = "sa/FETCH_ORGADMINS";
export const SA_FETCH_KEYWORKERS = "sa/FETCH_KEYWORKERS";
export const SA_FETCH_PARTNERS = "sa/SA_FETCH_PARTNERS";
export const SA_FETCH_EMPLOYERS = "sa/SA_FETCH_EMPLOYERS";
export const SA_FETCH_FLEXIBLE = "sa/SA_FETCH_FLEXIBLE";
export const SA_FETCH_HRMANAGERS = "sa/SA_FETCH_HRMANAGERS";
export const SA_FETCH_LINEMANAGERS = "sa/SA_FETCH_LINEMANAGERS";
export const SA_FETCH_FLEXIBLE_USER_DETAILS = "sa/SA_FETCH_FLEXIBLE_USER_DETAILS";
export const SA_PROVIDER_FETCH_DETAILS  = "sa/SA_PROVIDER_FETCH_DETAILS";
export const SA_SELECT_ORG = "sa/SELECT_ORG";
export const SA_ERROR = "sa/ERROR";
export const SA_DELETE = "sa/DELETE";
export const SA_LOADING = "sa/LOADING";
export const SA_FETCH_DUMMYKWS = "sa/FETCH_DUMMYKWS";
export const SA_FETCH_CASELOAD = "sa/FETCH_CASELOAD";
export const SA_FEEDBACK = "sa/FEEDBACK";
export const SA_FEEDBACK_DELETE = "sa/FEEDBACK/DELETE";
export const SA_DISPLAY_TOAST = "sa/SA_DISPLAY_TOAST";
export const SA_FEEDBACK_USER = "sa/FEEDBACK/USER";
export const SA_UNFULFILLED_ORDERS = "sa/ORDERS/UNFULFILLED";

//REWARDS
export const FETCH_COINS = "rewards/FETCH_COINS";
export const RESET_COINS = "rewards/RESET_COINS";

//

export const FETCH_ALL_OCCUPATIONS = "occupation/FETCH_All";

// caseload
export const CL_FETCH_CONTRACT_AND_PROVIDERS = "caseload/FETCH_CONTRACT_AND_PROVIDERS";
export const CL_FETCH_CASELOAD = "caseload/CL_FETCH_CASELOAD";
export const CL_FETCH_EVENTS = "caseload/CL_FETCH_EVENTS";
export const CL_FETCH_EXCEPTION_REPORT = "caseload/CL_FETCH_EXCEPTION_REPORT";
export const CL_RESET_EXCEPTION_REPORT = "caseload/CL_RESET_EXCEPTION_REPORT";
export const CL_FETCH_KEYWORKERS = "caseload/CL_FETCH_KEYWORKERS";
export const CL_FETCH_ORG_DATA = "caseload/CL_FETCH_ORG_DATA";
export const CLEAR_CASELOAD = "caseload/CLEAR_CASELOAD";


// jobs 

export const INDEED_DESCRIPTION = "jobs/INDEED_DESCRIPTION";

// bulk upload
export const UPDATE_BULK_UPLOAD_PROGRESS = "orgadmin/UPDATE_BULK_UPLOAD_PROGRESS";
export const SET_BULK_EMAIL_TEMPLATE = "orgadmin/SET_BULK_EMAIL_TEMPLATE";

// internalMobility
export const SET_ROLES = "im/SET_ROLES";
export const SET_DEPARTMENTS = "im/SET_DEPARTMENTS";
export const SET_DEMOGRAPHICS = "im/SET_DEMOGRAPHICS";
export const SET_LINEMANAGERS = "im/SET_LINEMANAGERS";
export const SET_UNVERIFIED = "im/SET_UNVERIFIED";