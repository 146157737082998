import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, CardContent, CardActions, Card, Typography, Backdrop, CircularProgress, Snackbar, SnackbarContent, Paper, Checkbox} from "@material-ui/core";
import { createCheckout } from "../../actions/auth_actions";
import { ReactComponent as SmLogo } from "../Common/Logo/smLogo.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const seatOptions = [
  {
    id: "seat1",
    title: "1 Seat",
    price: "£50",
    annualPrice: "£600"
  },
  {
    id: "seat3",
    title: "3 Seats",
    price: "£100",
    annualPrice: "£1200",
  },
  {
    id: "seat10",
    title: "10 Seats",
    price: "£200",
    annualPrice: "£2400"
  }
]

const creditOptions = [
  {
    id: "bundle250",
    title: "250 Credits",
    price: "£500"
  },
  {
    id: "bundle500",
    title: "500 Credits",
    price: "£800"
  },
  {
    id: "bundle1000",
    title: "1,000 Credits",
    price: "£1,500"
  },
  {
    id: "bundle3000",
    title: "3,000 Credits",
    price: "£4,000"
  },
  {
    id: "bundle5000",
    title: "5,000 Credits",
    price: "£5,000"
  },
  {
    id: "bundle10000",
    title: "10,000 Credits",
    price: "£6,000"
  }
]

const styles = theme => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  grid:{
    maxWidth: "800px",    
  },
  gridEmbeded:{
    maxWidth: "100%",
  },
  itemSpace:{
    margin:5
  },
  product:{
    "&:hover": {
      backgroundColor: "#ffb780",
      cursor: "pointer"
    }
  },
  selectedProduct: {
    background: "#F5F5F7",
    border:  "0.1em solid #FF9800",
    "&:hover": {
      backgroundColor: "#ffb780",
      cursor: "pointer"
    }
  },
  unSelectdProduct: {
    background: "#ff6a1405",
    "&:hover": {
      backgroundColor: "#ffb780",
      cursor: "pointer"
    }
  },
  titleText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "28px",
    textTransform: "uppercase",
    fontWeight: 500
  },
  subtitleText:{
    fontFamily: "Montserrat, sans-serif",
    fontSize: "22px",
    fontWeight: 500
  },
  bodyText:{
    fontFamily: "Montserrat, sans-serif",
    fontSize: "18px",
    fontWeight: 500
  },
  subText:{
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    fontWeight: 500
  },
  largeText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "48px",
    textTransform: "uppercase",
    fontWeight: 700
  },
  midText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "20px",
    textTransform: "uppercase",
    fontWeight: 700
  },
  smallText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    textTransform: "lowercase",
    fontWeight: 500
  },
  buttonText:{
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    textTransform:"capitalize"
  },
  snack: {
    marginTop: 10,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  paper: {
    maxWidth:800,
    padding:20
  },
  logo: {
    width: "100%",
    marginBottom: theme.spacing(2),
    maxHeight: 160,
    cursor: "pointer"
  },
  paperTitle:{
    fontFamily: "Montserrat, sans-serif",
    fontSize: "48px",
    fontWeight: 700
  },
  paperText:{
    padding:10,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "20px",
    fontWeight: 700
  },
  smallPaperText:{
    padding:10,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    textAlign:"center"
  },
});

const SeatProduct = ({classes, seat, selectedSeat, setSelectedSeat}) => {
  const {id, title, price} = seat;
  return (
    <Grid item className={classes.itemSpace} onClick={() => {
      if(selectedSeat && selectedSeat.id === id){
        setSelectedSeat(undefined)
      }
      else{
        setSelectedSeat(seat)
      }
    }}>
      <Card className={selectedSeat ? selectedSeat.id === id ? classes.selectedProduct : classes.unSelectdProduct : classes.product}>
        <CardContent>
          <Typography className={classes.midText}>{title}</Typography>
          <Typography style={{display:"flex", alignItems:"baseline"}} className={classes.largeText}>
            {price}<Typography className={classes.smallText}>{"/month"}</Typography>
          </Typography>
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>
    </Grid>
  )
}

const CreditProduct = ({classes, credits, selectedCredits, setSelectedCredits}) => {
  const {id, title, price} = credits;
  return (
    <Grid item className={classes.itemSpace} onClick={() => {
      setSelectedCredits(credits)
      if(selectedCredits && selectedCredits.id === id){
        setSelectedCredits(undefined)
      }
      else{
        setSelectedCredits(credits)
      }
    }}>
      <Card className={selectedCredits ? selectedCredits.id === id ? classes.selectedProduct : classes.unSelectdProduct : classes.product}>
        <CardContent style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <Typography className={classes.midText}>{title}</Typography>
          <Typography style={{display:"flex", alignItems:"baseline"}} className={classes.largeText}>
            {price}
          </Typography>
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>
    </Grid>
  )
}

const SuccessPopUp = ({isEmbeded, classes}) => {
  return (
    <Paper elevation={1} className={classes.paper}>
      {isEmbeded && (
        <SmLogo className={classes.logo} onClick={() => window.top.location.href = "https://www.skillsminer.ai"}/>
      )}
      <Typography className={classes.paperTitle}>
        Payment Successful
      </Typography>
      <Typography className={classes.paperText}>
        You should receive an email receipt shortly.
      </Typography>
      <Typography className={classes.paperText}>
        Once your order has been processed
        We will contact you via email within the next 48 hours with your credentials to login.
      </Typography>
      <Typography className={classes.paperText}>
        If you have any questions, please contact us at <Link
            to='#'
            onClick={(e) => {
                window.location.href = "enquiries@skillsminer.ai"
                e.preventDefault();
            }}
        >
            {"enquiries@skillsminer.ai"}
        </Link>
      </Typography>
      {!isEmbeded && (
        <Typography className={classes.smallPaperText}>
          Click <Link to={{ pathname: "https://www.skillsminer.ai"}} target="_blank">{"here"}</Link> to return to our website 
        </Typography>
      )}
    </Paper>
  )
}

const ConfirmPopup = ({isEmbeded, classes, onSubmit}) => {
  const [terms, setTerms] = useState(false);
  return (
    <Paper elevation={1} className={classes.paper}>
        <SmLogo className={classes.logo} onClick={() => window.top.location.href = "https://www.skillsminer.ai"}/>
      <Typography className={classes.paperTitle}>
        Continue to Secure Checkout
      </Typography>
      <Typography className={classes.paperText}>
        To complete your purchase, you will be redirected to Stripe, our secure payment processor. Stripe handles all payment information according to industry-leading security standards.
      </Typography>
      <Typography className={classes.paperText}>
        To use and access Skillsminer, you must agree to our the terms of our <Link to={{ pathname: "https://www.skillsminer.ai/privacy-policy/"}} target="_blank">{"Privacy Policy"}</Link> and our <Link to={{ pathname: "https://www.skillsminer.ai/terms-of-use/"}} target="_blank">{"Terms Of Use"}</Link>.
      </Typography>
      <Typography className={classes.paperText}>
        I accept the terms of the privacy policy and terms of use.
        <Checkbox checked={terms} onChange={() => setTerms(!terms)}/>
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        disabled={!terms}
        onClick={async () => await onSubmit()}
      >
        <Typography className={classes.buttonText}>Continue to payment</Typography>
      </Button>
      <Typography className={classes.smallPaperText}>
        If you have any questions, please contact us at <Link
            to='#'
            onClick={(e) => {
                window.location.href = "enquiries@skillsminer.ai"
                e.preventDefault();
            }}
        >
            {"enquiries@skillsminer.ai"}
        </Link>
      </Typography>
    </Paper>
  )
}

class PricingTable extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  state={
    urlString: window.location.search,
    successEvent: false,
    selectedSeat:undefined,
    selectedCredits:undefined,
    loading: false,
    errorMessage: false,
    isEmbeded: false,
    confirmPopup: false
  }

  componentDidMount() {
    if (window.location !== window.top.location) {
      document.body.style.background = "transparent"
      this.setState({isEmbeded: true})
    }

    let queryDict = {}
    this.state.urlString.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})
    if(queryDict && queryDict.stripesuccess){
      this.setState({successEvent: true})
    }
  }

  async submit() {
    let recaptchaValue = this.recaptchaRef && this.recaptchaRef.current ? this.recaptchaRef.current.getValue(): false;

    this.setState({loading:true})
    if(!recaptchaValue){
      this.setState({loading:false, errorMessage: "Please complete the captcha"})
    }
    else{
      const checkoutURL = await this.props.createCheckout(this.state.selectedSeat, this.state.selectedCredits, recaptchaValue);
      if(checkoutURL.url){
        this.setState({loading:false, confirmPopup: false})
        window.top.location.href = checkoutURL.url
      }
      else{
        this.setState({loading:false, errorMessage: checkoutURL.error})
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {selectedSeat, selectedCredits, loading, errorMessage, successEvent, isEmbeded, confirmPopup} = this.state;
    return (
      <div className={classes.main}>
        {loading && (
          <Backdrop  style={{zIndex:9000}} open={true}> 
            <CircularProgress  size={28} />
          </Backdrop>
        )}
        {successEvent && (
          <Backdrop  style={{zIndex:8001}} open={true}> 
            <SuccessPopUp isEmbeded={isEmbeded} classes={classes}/>
          </Backdrop>
        )}
        {confirmPopup && (
          <Backdrop  style={{zIndex:8000}} open={true}> 
            <ConfirmPopup isEmbeded={isEmbeded} classes={classes} onSubmit={async () => await this.submit()}/>
          </Backdrop>
        )}
        <Snackbar
          className={classes.snack}
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={errorMessage ? true : false}
          onClose={() => this.setState({ errorMessage: false })}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{errorMessage}</span>}
          />
        </Snackbar>
        <Grid
          className={isEmbeded ? classes.gridEmbeded : classes.grid}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          {!isEmbeded && (
            <SmLogo className={classes.logo} style={{marginTop:20}} onClick={() => window.top.location.href = "https://www.skillsminer.ai"}/>
          )}
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.titleText}>
              Mine, Match and Mobilise Hidden Talent
            </Typography>
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.subtitleText}>
              In just a few clicks you can access the power of Skillsminer and find the talent you need
            </Typography>
          </Grid>    
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.bodyText}>
              Each Account gives one member of your team access to Skillsminer's ProAct portal. Then they can mine for matches with your vacant roles. You’ll be able to mine your own talent pool and our exclusive Skillsminer talent pool. You can create vacancies and personalise your own virtual assistant to contact your matches for you.
            </Typography>
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              {seatOptions.map((item) => (
                <SeatProduct
                  classes={classes}
                  seat={item}
                  selectedSeat={selectedSeat}
                  setSelectedSeat={(value) => this.setState({selectedSeat: value})}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.subText}>
              Seat prices are billed annually, providing access for the entire year.
            </Typography>
          </Grid>
          
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.bodyText}>
              If you want to mine from an even bigger pool you can buy Credits. This lets you mine and match from an even larger pool of over 3 million talented people from throughout the UK.
            </Typography>
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Typography className={classes.bodyText}>
              One Credit allows you to download one additional CV and contact details for one, unique matched individual.
            </Typography>
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{margin:20}}
            >
              {creditOptions.map((item) => (
                <CreditProduct
                  classes={classes}
                  credits={item}
                  selectedCredits={selectedCredits}
                  setSelectedCredits={(value) => this.setState({selectedCredits: value})}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item className={classes.itemSpace}>
          <ReCAPTCHA
            style={{marginTop: 15}}
            ref={this.recaptchaRef}
            sitekey="6LfkUdoaAAAAACWTV3biAMmcp8JLPQpsuWzrFDMa"
          />
          </Grid>
          <Grid item className={classes.itemSpace}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={!selectedSeat}
              onClick={() => this.setState({confirmPopup: true})}
            >
              <Typography className={classes.buttonText}>Buy Now</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        createCheckout
      },
      dispatch
    ),
    dispatch,
  };
}

PricingTable = connect(mapStateToProps, mapDispatchToProps)(PricingTable);

export default withStyles(styles)(PricingTable);
