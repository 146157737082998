import React, { Component, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Dialog,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
  Tooltip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
// import { OccupationSearch } from '../Main/OccupationSearch'
import {OccupationAutoComplete} from "../../../Common/OccupationAutoComplete"
import { ReactComponent as SkillsEditIcon } from "./icons/skilledit.svg";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20
  },
  titleContainer:{
    backgroundColor: "white",
    borderBottom: "1px solid #EDEFF2",
    textAlign: "center",
  },
  titleRow:{
    width:"100%",
    justifyContent:"space-between"
  },
  bodyContainer:{
    padding: 20
  },
  title:{
    fontSize: 16,
    fontWeight: "bold",
    color: "#5A6375",
    textTransform: "capitalize",
    alignItems: "center",
    display: "flex"
  },
  icon: {
    marginInline: "10px",
  },
  skillRow:{
    borderTop: "1px solid #EDEFF2",
  },
  skillName:{
    fontSize: 14,
    color: "#5A6375",
    textTransform: "capitalize",
  },
  buttonContainer:{
    display:"flex",
    flexDirection: "row-reverse"
  }
}));


export default function NewSkills({open, closePanel, existingSkills}){
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [newSkillList, setNewSkillList] = useState([]);
  return (
    <Dialog 
      closePanel={() => {
        setNewSkillList([])
        closePanel()
      }}
      open={open}
      fullScreen={fullScreen}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.container}
      >
        <Grid item xs={12} className={classes.titleContainer}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.titleRow}
          >
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                <SkillsEditIcon className={classes.icon} />
                Add new skills
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                className={`close-button`}
                onClick={() => {
                  closePanel();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.bodyContainer}>
          <OccupationAutoComplete
            size={"large"}
            classes={classes}
            type={"skills"}
            placeholder="Add new Skills"
            onSelect={async (value)=>{
              if (value){
                setNewSkillList([...new Set([...newSkillList, value])]);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.list}>
          {newSkillList.map((skill) => ( 
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}
              className={classes.skillRow}
            >
              <Grid item xs={2}>
                <Tooltip title={"Remove Skill"} arrow placement="top-start">
                  <IconButton
                      aria-label="delete"
                      className={`skill-delete-${skill.replace(/ /gi,"-")}`}
                      onClick={() => setNewSkillList(newSkillList.filter(item => skill !== item))}
                    >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>      
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" className={classes.skillName}>
                  {existingSkills.includes(skill) ? skill + " (You already have this skill)" : skill}
                </Typography>
              </Grid>
          </Grid>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button 
            variant="contained" 
            color="primary" 
            size="small" 
            onClick={async () => {
              closePanel(newSkillList.filter(item => !existingSkills.includes(item)))
              setNewSkillList([])
            }}
          >
              Save Skills
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}