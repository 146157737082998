import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logout } from "../../actions/auth_actions";
import { fetchCandidates } from "../../actions/candidates_actions";
import { getBillingConsole } from "../../actions/portal_actions"
import * as ROUTES from "../../constants/routes";
import { Can } from "../Abilities/";
import { ContractSwitcherMenu } from "./ContractSwitcherMenu";
import config from "../../config";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    display: "flex",
  },
  appbarColor: {
    backgroundColor: "#333",
  },
  logo: {
    //backgroundColor :"#000000",
    height: 28,
    padding: 3,
  },
  layout: {},
  name: {
    textDecoration: "none",
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: "white",
    "&:hover": {
      //background: theme.palette.primary.main
    },
  },
  badge:
    {
      color: "white",
      backgroundColor: "red"
    },
  progress: {},
  ltext: {},
  logoholder: {},
  logok: {},
  progressCont: {},
  loadernobg: {},
  loader: {},
});

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  state = {
    anchorEl: null,
    openContractAnchor: null,
    version:
      process.env.REACT_APP_STAGE +
      "@" +
      process.env.REACT_APP_VERSION +
      (process.env.REACT_APP_BUILD
        ? "." + parseInt(process.env.REACT_APP_BUILD, 10)
        : ""),
  };

  logout() {
    this.props.logout(null, this.props.history);
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuContract = (event) => {
    this.setState({ openContractAnchor: event.currentTarget });
  };
  handleCloseContract = () => {
    this.setState({ openContractAnchor: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount = async () => {
    //await this.props.fetchCandidates();
  };

  getVariant(route) {
    if (this.props.location.pathname.indexOf(route) === 0) {
      return "contained";
    } else {
      return "text";
    }
  }

  getColor(route) {
    if (
      this.props.location.pathname.indexOf(ROUTES.PROFILE) === 0 ||
      this.props.location.pathname.indexOf(ROUTES.ADMIN) === 0
    ) {
      return "primary";
    } else {
      return "inherit";
    }
  }

  render() {
    const { classes, portaldetails = {}, portalusers={}, vacancies={} } = this.props;
    const { orgdetails = {}, user = {}, unverifiedRolesLength = 0, unverifiedDepartmentsLength = 0, unverifiedLineManagersLength = 0 } = portaldetails;
    const { snackBarText } = vacancies;
    const { anchorEl, version } = this.state;
    const totalNotifcaitons = unverifiedRolesLength + unverifiedDepartmentsLength + unverifiedLineManagersLength
    const open = Boolean(anchorEl);
    const isInternalMobility = user.role === "hr-manager" || user.role === "line-manager";
    const { isCustomer=false } = portalusers;
    const disabledNav = snackBarText ? true : false; //for preventing nav while search is going
    return ( 
      <div className={classes.root}>
        <AppBar
          position="static"
          classes={{
            colorPrimary: classes.appbarColor,
          }}
        >
          <Toolbar>
            <Link to="/">
              <Button>
                <img
                  //src={"/images/logo_gray.png"}
                  src={
                    config.STORAGE_CLOUDFRONT_URL +
                    (orgdetails.navLogoURL
                      ? orgdetails.navLogoURL + "?"
                      : "/logos/default_portal_nav_logo.png?") +
                    Date.now()
                  }
                  className={classes.logo}
                  alt={version}
                  title={version}
                />
              </Button>
            </Link>
            <div className={classes.grow}>
              {/* <Can I="visit" this={ROUTES.SKILLZMINER}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant={this.getVariant(ROUTES.SKILLZMINER)}
                  size="small"
                  component={Link}
                  to={ROUTES.SKILLZMINER}
                >
                  Skillzminer
                </Button>
              </Can> */}
              <Can I="visit" this={ROUTES.VACANCIES}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant={this.getVariant(ROUTES.VACANCIES)}
                  size="small"
                  component={Link}
                  to={ROUTES.VACANCIES}
                  disabled={disabledNav}
                >
                  {isInternalMobility ? "Vacancies & Resource Matching" : "Vacancies"}
                </Button>
              </Can>
              <Can I="visit" this={ROUTES.CASELOADS}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="small"
                  component={Link}
                  variant={this.getVariant(ROUTES.CASELOADS)}
                  to={ROUTES.CASELOADS}
                  disabled={disabledNav}
                >
                  Caseloads
                </Button>
              </Can>
              <Can I="visit" this={ROUTES.CANDIDATES}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="small"
                  component={Link}
                  variant={this.getVariant(ROUTES.CANDIDATES)}
                  to={ROUTES.CANDIDATES}
                  disabled={disabledNav}
                >
                  Candidates
                </Button>
              </Can>
              <Can I="visit" this={ROUTES.DASHBOARDS}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="small"
                  component={Link}
                  variant={this.getVariant(ROUTES.DASHBOARDS)}
                  to={ROUTES.DASHBOARDS}
                  disabled={disabledNav}
                >
                  Dashboards
                </Button>
              </Can>
              <Can I="visit" this={ROUTES.SUPER_ADMIN_FEEDBACK}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="small"
                  component={Link}
                  variant={this.getVariant(ROUTES.SUPER_ADMIN_FEEDBACK)}
                  to={ROUTES.SUPER_ADMIN_FEEDBACK}
                  disabled={disabledNav}
                >
                  Feedback
                </Button>
              </Can>
              {isInternalMobility && (
                <>
                  <Tooltip title={"Coming Soon"}>                  
                    <Button
                      className={classes.button}
                      color="primary"
                      size="small"
                      disabled={disabledNav}
                    >
                      Employees
                    </Button>
                  </Tooltip>
                  <Tooltip title={"Coming Soon"}>                  
                    <Button
                      className={classes.button}
                      color="primary"
                      size="small"
                      disabled={disabledNav}
                    >
                      Organisational Chart
                    </Button>
                  </Tooltip>
                  <Tooltip title={"Coming Soon"}>                  
                    <Button
                      className={classes.button}
                      color="primary"
                      size="small"
                      disabled={disabledNav}
                    >
                      Dashboard & Leaderboards
                    </Button>
                  </Tooltip>
                </>
              )}
              
            </div>
            <Can I="see" this={"CONTRACT_SWITCHER"}>
              <div style={{ paddingRight: "1em" }}>
                <ContractSwitcherMenu />
              </div>
            </Can>
            <Typography
              variant="body2"
              color="inherit"
              className={classes.name}
              onClick={this.handleMenu}
            >
              <Badge badgeContent={totalNotifcaitons} classes={{badge : classes.badge}} style={{margin:10}}>
                <NotificationsIcon color="white"/>
              </Badge>
              {this.props.auth.currentAuthUser &&
                this.props.auth.currentAuthUser.attributes &&
                (this.props.auth.currentAuthUser.attributes.given_name
                  ? this.props.auth.currentAuthUser.attributes.given_name
                  : this.props.auth.currentAuthUser.attributes.email)}
            </Typography>
            <div>
              <IconButton
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color={this.getColor()}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={this.handleClose}
              >
                {this.props.auth.currentAuthUser && (
                  <MenuItem disabled>
                    {this.props.auth.currentAuthUser.attributes.email}
                  </MenuItem>
                )}

                <Divider light />
                <Can I="visit" this={ROUTES.PROFILE}>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={ROUTES.PROFILE}
                    disabled={disabledNav}
                  >
                    Account
                  </MenuItem>
                </Can>
                <Can I="visit" this={ROUTES.FAQ}>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={ROUTES.FAQ}
                    disabled={disabledNav}
                  >
                    FAQ's
                  </MenuItem>
                </Can>
                {isCustomer && (
                  <MenuItem
                    onClick={async () => {
                      const portal = await this.props.getBillingConsole()
                      if(portal && portal.url){
                        window.location.replace(portal.url)
                      }
                    }}
                    disabled={disabledNav}
                  >
                    Manage Billing
                  </MenuItem>
                )}
                <Can I="visit" this={ROUTES.ORGANISATION}>
                  <MenuItem
                    style={{display:"flex", justifyContent:"space-between"}}
                    onClick={this.handleClose}
                    component={Link}
                    to={ROUTES.ORGANISATION}
                    disabled={disabledNav}
                  >
                    Admin
                    <Badge badgeContent={totalNotifcaitons} classes={{badge : classes.badge}} style={{margin:10}}/>
                  </MenuItem>
                </Can>

                <Divider light />
                <MenuItem onClick={this.logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        logout,
        fetchCandidates,
        getBillingConsole
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation));
