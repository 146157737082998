import { API } from "aws-amplify";
import config from "../config/";
import {INDEED_DESCRIPTION} from "./types";

export const getIndeedDescription = (id) => async (dispatch) => {
  try {
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobs/query/indeed_job_description", {
    /* eslint-enable */
      body: {
        data: {
          "job_id": id
        }
      },
    });
    if(jobs && jobs){
      return jobs
    }

  } catch (error) {
    console.log(error);
    // TODO
  }
};

export const getReedDescription = (id) => async (dispatch) => {
  try {
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobs/query/reed_job_details", {
    /* eslint-enable */
      body: {
          "id": id
      },
    });
    if(jobs && jobs.jobDescription){
      return jobs.jobDescription
    }

  } catch (error) {
    console.log(error);
    // TODO
  }
};