import { API, Logger, Storage } from "aws-amplify";
import config from "../config";
import { ADD_VACANCY, FETCH_VACANCIES, EDIT_VACANCY, FETCH_VACANCIES_CANDIDATES, FIND_VACANCIES_STATUSES, SET_CANDIDATE_DETAILS, UPDATE_CANDIDATE_INFO, SET_VACANCY_BY_ID, ADD_SEARCH, FETCH_SEARCHES, REMOVE_SEARCH, EDIT_SEARCH, FETCH_SEARCH_CANDIDATES, FETCH_SEARCH_SHORTLIST, UPDATE_SEARCH_SHORTLIST, FILTER_SEARCH_CANDIDATES, FETCH_BOT_TEMPLATE, EDIT_TEMPLATE, ADD_TEMPLATE, REMOVE_TEMPLATE, UPDATE_VIEWED_CANDIDATES, REMOVE_VACANCY, UPDATE_SNACKBAR, FETCH_VACANCIES_CANDIDATES_MONSTER, GET_VIEW_PREFS, REMOVE_VACANCIES_CANDIDATES } from "./types";
import _ from 'lodash';
const moment = require("moment");
const logger = new Logger("VACANCIES.ACTIONS", "INFO");


export const inviteToVacancy = (user, vacancy, status, shortlist) => {
  let body = {
    userId:  user.userID,
    jobId: vacancy.job_id,
    jobTitle: vacancy.job_title,
    jobSort: vacancy.sort,
    employer: vacancy.employerName,
    location : vacancy.original_location === "Work from Home / Remote / Nationwide" ? "Work from Home / Remote / Nationwide": vacancy.location_full,
    salary : Math.round(vacancy.annual_salary_min),
    jobDetails : {
      score_bp:user.skills,
      score_interest:user.interests,
      score_styles:user.styles,
      similar_skills: user.similar_skills,
      missing_skills: user.missing_skills
    }
  };
  if (vacancy.hourly_salary_min){
    body.salary = vacancy.hourly_salary_min + " per hour"
  }

  if (status === "invited"){
    body.invited = true;
  } else if (status === "removed"){
    body.removed = true;
  } else if (status === "hired"){
    body.hired = true;
  }
  return async (dispatch) => {
    try {
      /*eslint-disable */
      let data = await API.post(config.API_NAME, "/employer/job/user/status", {
        body: body
      });
      /*eslint-enable */
      if(!shortlist){
        await dispatch({ type: UPDATE_CANDIDATE_INFO, data: body, status });
      }
      await dispatch(findAllStatuses(vacancy.job_id))
      await dispatch(fetchDetailedCandidate(body.userId, vacancy, true))
    } catch (e) {
      logger.error("inviteToVacancy():", e);
    }
  }
}


export const inviteListToVacancy = (userList, vacancy) => {
  return async (dispatch) => {
    let body = {
      userId:  null,
      jobId: vacancy.job_id,
      jobTitle: vacancy.job_title,
      jobSort: vacancy.sort,
      employer: vacancy.employerName,
      location : vacancy.original_location === "Work from Home / Remote / Nationwide" ? "Work from Home / Remote / Nationwide": vacancy.location_full,
      salary : Math.round(vacancy.annual_salary_min),
      invited : true,
      jobDetails:{}
    };
    if (vacancy.hourly_salary_min){
      body.salary = vacancy.hourly_salary_min + " per hour"
    }
    await new Promise(async (resolve) => {
      for (const element of userList){
        body.userId = element.userID
        body.jobDetails = {
          score_bp:element.score_bp,
          score_interest:element.score_interest,
          score_styles:element.score_styles,
          similar_skills: element.similar_skills,
          missing_skills: element.missing_skills
        }
        try {
          /*eslint-disable */
          let data = await API.post(config.API_NAME, "/employer/job/user/status", {
            body: body
          });
          /*eslint-enable */
        } catch (e) {
          logger.error("inviteListToVacancy():", e);
        }
      }
      resolve();
    });
    await dispatch(findAllStatuses(vacancy.job_id));
  }
}

export const setFavouritedCandidate = (userId, vacancy, isFavourited, freeCandidate, searchCandidate) => {
  return async dispatch => {
    if(searchCandidate){
      try {
        const data = await API.post(config.API_NAME, "/employer/job/search/favourite", {
          body: {
            vacancy:  vacancy,
            candidate: searchCandidate,
            isFavourited: isFavourited
          }
        })
        return data
      } catch (e) {
        logger.error("setFavouritedCandidate Search Cand():", e);
      }
    }
    else{
      let body = {
        userId:  userId,
        jobId: vacancy.searchOnly ? vacancy.sort : vacancy.job_id,
        isFavourited: isFavourited,
        isSearch: vacancy.searchOnly ? true : false
      };
      if (freeCandidate){
        body.freeCandidateDetails = {
          firstName : freeCandidate.firstName,
          lastName :freeCandidate.lastName,
          email: freeCandidate.email,
          telephone: freeCandidate.phoneNumber,
          data: freeCandidate
        }
      }
    
      try {
        const data = await API.post(config.API_NAME, "/employer/job/user/favourite", {
          body: body }
        )
        return data
      } catch (e) {
        logger.error("setFavouritedCandidate():", e);
      }
    }
  };
};

export const setBulkFavouriteCandidates = (userIdList, vacancy) => {
  return async (dispatch, getState) => {
    const { vacancies:{ vacancyCandidates = []}  } = getState();

    let body = {
      userId:  null,
      jobId: vacancy.searchOnly ? vacancy.sort : vacancy.job_id,
      isFavourited: true
    };
    await new Promise(async (resolve) => {
      for (const element of userIdList){
        const foundCand = vacancyCandidates.find((item) => item.userID === element.userID);
        body.userId = element
        if(!foundCand.favourited){
          await dispatch(setFavouritedCandidate(foundCand.userID, vacancy, true, foundCand.free_resume ? foundCand : false, foundCand.talentType === "Premium External Profiles"? foundCand : false))
        }
      }
      resolve();
    });
    await dispatch(findAllStatuses(vacancy.searchOnly ? vacancy.sort : vacancy.job_id));
  }
}

export const removeCandidatesFromVacancy = (userIdList, jobId, isPotential) => {
  return async dispatch => {
    let body = {
      userIdList:  userIdList,
      jobId: jobId
    };
  
    try {
      const data = API.post(config.API_NAME, "/employer/job/user/remove", {
        body: body }
      )
      await dispatch({ type: REMOVE_VACANCIES_CANDIDATES, candidatesToRemove: userIdList,  isPotential: isPotential});
      return data
    } catch (e) {
      logger.error("removeCandidatesFromVacancy():", e);
    }
  };
};

export const restoreRemovedCandidates = (selectedVacancy) => {
  return async dispatch => {
    let body = {
      jobId: selectedVacancy.searchOnly ? selectedVacancy.sort : selectedVacancy.job_id
    };
  
    try {
      /*eslint-disable */
      const data = await API.post(config.API_NAME, "/employer/job/user/removed/restore", {
        body: body }
      )
      /*eslint-enable */
      await dispatch(fetchCandidates(selectedVacancy))
    } catch (e) {
      logger.error("restoreRemovedCandidates():", e);
    }
  };
};

export function fetchVacancies(provider) {
  return async function(dispatch) {
    logger.info("fetchVacancies(): ");
    try {
      let { data } = await API.post(config.API_NAME, "/employer/vacancies/get", {
        body: {
          provider: provider
        }
      });
      dispatch({ type: FETCH_VACANCIES, data: data });
    } catch (e) {
      logger.error("fetchVacancies():", e);
    }
  };
}

export function refreshVacancies(vacancies) {
  return async function(dispatch) {  
    dispatch({ type: FETCH_VACANCIES, data: vacancies });
  };
}

export const addNewVacancy = vacancy => {
  return async dispatch => {
    try {
      const removeOldSearch = vacancy.searchOnly ? true : false
      const data = await API.post(config.API_NAME, "/employer/vacancies", {
        body: { ...vacancy, id: undefined, sort: undefined, searchOnly: undefined }
      });
      if (data.message) {
        return { success: false, message: data, error: data };
      }
      else{
        let jobDB_vac = _.cloneDeep(data.data)
        delete jobDB_vac.onet_styles['Speed']
        delete jobDB_vac.onet_styles['Learning_styles']
        jobDB_vac.highest_interest = Object.entries(jobDB_vac.onet_interests).sort(([,a],[,b]) => b-a).map((item) => item[0])
        jobDB_vac.highest_styles = Object.entries(jobDB_vac.onet_styles).sort(([,a],[,b]) => b-a).map((item) => item[0])
        /*eslint-disable */
        const jobDB_data = await API.post(config.API_NAME, "/jobs/query/vacancy_to_global", {
          body: jobDB_vac
        });

        //
        if(removeOldSearch){
          const transferShortList = await API.post(config.API_NAME, "/employer/transferShortlist", {
            body: {
              vacancy: data.data,
              search: vacancy
            }
          });

          const removedSearch = await API.post(config.API_NAME, "/employer/deleteSearch", {
            body: vacancy
          });
          dispatch({ type: REMOVE_VACANCY, data: vacancy });
        }
        /*eslint-enable */
      }
      dispatch({ type: ADD_VACANCY, data: data.data });
      return { success: true };
    } catch (e) {
      throw e;
    }
  };
};

export const addNewSearch = newSearch => {
  return async dispatch => {
    try {
      const data = await API.post(config.API_NAME, "/employer/createSearch", {
        body: {
          ...newSearch, 
        }
      });
      if (data && data.success){
        dispatch({ type: ADD_VACANCY, data: data.data });
        return { success: true };
        
      }
      else{
        return { success: false, message: data, error: data };
      }
    } catch (e) {
      throw e;
    }
  };
};

export const editVacancy = vacancy => {
  return async dispatch => {
    try {
      const data = await API.patch(config.API_NAME, "/employer/vacancies", {
        body: { ...vacancy }
      });
      if (data.message) {
        return { success: false, message: data, error: data };
      }
      else{
        let jobDB_vac = _.cloneDeep(data.data)
        delete jobDB_vac.onet_styles['Speed']
        delete jobDB_vac.onet_styles['Learning_styles']
        jobDB_vac.highest_interest = Object.entries(jobDB_vac.onet_interests).sort(([,a],[,b]) => b-a).map((item) => item[0])
        jobDB_vac.highest_styles = Object.entries(jobDB_vac.onet_styles).sort(([,a],[,b]) => b-a).map((item) => item[0])
        /*eslint-disable */
        const jobDB_data = await API.post(config.API_NAME, "/jobs/query/vacancy_to_global", {
          body: jobDB_vac
        });
        /*eslint-enable */
      }
      dispatch({ type: EDIT_VACANCY, data: data.data });
      return { success: true };
    } catch (e) {
      return { success: false, message: e.message };
    }
  };
};

export const setVacancyStatus = (vacancy, status) => {
  return async (dispatch) => {
    try {
      /*eslint-disable */
      const body = await API.patch(config.API_NAME, "/employer/vacancy/status/set", {
        body: {
          jobId: vacancy.job_id,
          status: status
        }
      });
      await API.post(config.API_NAME, "/jobs/query/update_vacancy_status", {
        body: {
          id: vacancy.job_id,
          status: status.charAt(0).toUpperCase() + status.slice(1)
        }
      });
      /*eslint-enable */
      vacancy.status = status
      dispatch({ type: EDIT_VACANCY, data: vacancy });
    } catch (e) {
      logger.error("setVacancyStatus():", e);
    }
  }
}

export const getVacancyDetails = (vacancy) => {
  return async (dispatch) => {
    try {
      if(!vacancy.searchOnly){
        const data = await API.patch(config.API_NAME, "/employer/vacancy/details/get", {
          body: {
            jobId: vacancy.job_id
          }
        })
        
        if (data){
          vacancy.status = data.status ? data.status : "open"
        }
        else{
          vacancy.status = "open"
        }
        dispatch({ type: EDIT_VACANCY, data: vacancy });
      }
    } catch (e) {
      logger.error("getVacancyDetails():", e);
    }
  }
}

export function fetchCandidates(selectedVacancy, mineGlobally, includeInternalProvider){
  return async function (dispatch, getState) {
    const { portaldetails: { user:{ organisation = ""}  } } = getState();

    logger.info("fetchCandidates(): ");
    if (selectedVacancy){
      dispatch({ type: UPDATE_SNACKBAR, data: "Searching for Skillsminer Profiles" });
      const onlySkills = selectedVacancy.occupation.length === 0 ? true : false
      //get blacklist
      let removedUsers = []
      let jobID = selectedVacancy.searchOnly ? selectedVacancy.sort : selectedVacancy.job_id
      try {
        const data = await API.post(config.API_NAME, "/employer/job/user/removed/get", {
          body: {
            jobId: jobID
          }
        })
        if(data){
          removedUsers = data.userList
        }
      } catch (e) {
        logger.error("fetchRemovedCandidates error():", e);
      }

      try {
        const { jobs } = await API.post(config.API_NAME, "/jobs/query/reverse_mining", {
          body: {...selectedVacancy, occupation: onlySkills ? false : selectedVacancy.occupation, mineGlobally: mineGlobally, includeInternalProvider:includeInternalProvider}
        });
        if (jobs) {
          dispatch({ type: FETCH_VACANCIES_CANDIDATES, data: jobs, jobID: jobID, removedUsers: removedUsers, organisation: organisation });
        }
        dispatch({ type: UPDATE_SNACKBAR, data: "Searching for External Profiles" });
        try {
          //now do search
          const isRemote = selectedVacancy.location_full === "1234567891234567891" ? true : false
          const request_body = {
            job_title: selectedVacancy.job_title,
            location: isRemote ? selectedVacancy.location_full : selectedVacancy.location_full.includes("Greater London") ? "London" : selectedVacancy.original_location,
            postcode: isRemote ? "" : selectedVacancy.location_full.split(",")[2].replace(" ", ""),
            lat: selectedVacancy.lat ? selectedVacancy.lat : "52.065299",
            lon: selectedVacancy.lon ? selectedVacancy.lon : "-0.187183" ,
            occupation: onlySkills ? [] : [selectedVacancy.occupation],
            required_skills: selectedVacancy.skills_required || []
          }

          const monsterSearch = await API.post(config.API_NAME, onlySkills ? "/jobs/query/candidate_skills_search_monster": "/jobs/query/candidate_search_monster", {
            body: request_body
          })
          if(monsterSearch && monsterSearch.jobs){
            dispatch({ type: FETCH_VACANCIES_CANDIDATES_MONSTER, data: monsterSearch.jobs, jobID, removedUsers, organisation: organisation });
          }
        } catch (e) {
          logger.error("fetchCandidates SEARCH error():", e);
        }
        dispatch({ type: UPDATE_SNACKBAR, data: "" });
        
      } catch (e) {
        logger.error("fetchCandidates():", e);
        dispatch({ type: FETCH_VACANCIES_CANDIDATES, data: false });
      }
    }
    else{
      dispatch({ type: FETCH_VACANCIES_CANDIDATES, data: false });
    }
  };
};

export const fetchDetailedCandidate = (candidateID, selectedVacancy, isPotential, candidateData) => {
  return async (dispatch) => {
    logger.info("fetchDetailedCandidate(): ");
    try {
      let data = false
      if(candidateData){
        data = candidateData
      }
      else{
        data = await API.post(config.API_NAME, "/employer/job/user/details", {
          body: {
            candidateID: candidateID
          }
        });
      }
      dispatch({type: SET_CANDIDATE_DETAILS, data: data, selectedVacancy, isPotential});
    } catch (e) {
      logger.error("fetchDetailedCandidate():", e);
    }
  }
}


export const findAllStatuses = (jobId) => {
  return async (dispatch) => {
    try {
      if(jobId){
        let statusData = await API.post(config.API_NAME, "/employer/job/status", {
          body: {
            jobId: jobId 
          }
        });
        let removedUsers = []
        try {
          const data = await API.post(config.API_NAME, "/employer/job/user/removed/get", {
            body: {
              jobId: jobId
            }
          })
          if(data){
            removedUsers = data.userList
          }
        } catch (e) {
          logger.error("fetchRemovedCandidates error():", e);
        }
        dispatch({type: FIND_VACANCIES_STATUSES, shortList: statusData.shortList, removedUsers, globalUnlocked: statusData.globalUnlocked});
      }
      else{
        dispatch({type: FIND_VACANCIES_STATUSES, shortList: [], removedUsers:[], globalUnlocked: []});
      }
    } catch (e) {
      logger.error("findAllStatuses():", e);
    }
  }
}



export const getVacancyById = (jobId) => {
  return async (dispatch) => {
    try {
      let data = await API.post(config.API_NAME, "/employer/vacancies/getById", {
        body: {
          jobId: jobId 
        }
      });
     
      dispatch({type: SET_VACANCY_BY_ID, data: data});
    } catch (e) {
      logger.error("findAllStatuses():", e);
    }
  }
}

export function fetchSearches(provider) {
  return async function(dispatch) {
    logger.info("fetchSearches(): ");
    try {
      let { data } = await API.post(config.API_NAME, "/employer/searches/get", {
        body: {
          provider: provider
        }
      });
      dispatch({ type: FETCH_SEARCHES, data: data });
    } catch (e) {
      logger.error("fetchSearches():", e);
    }
  };
}

export const deleteSearch = search => {
  return async dispatch => {
    try {
      const data = await API.post(config.API_NAME, "/employer/deleteSearch", {
        body: search
      });
      if (data && data.success){
        dispatch({ type: REMOVE_VACANCY, data: search });
        return { success: true };
      }
      else{
        return { success: false };
      }
    } catch (e) {
      throw e;
    }
  };
};

export function fetchHiddenTalent(location){
  return async function (dispatch, getState) {
    // const { vacancies: { searchShortlist=[]  } } = getState();
    logger.info("fetchHiddenTalent(): ");
    let response
      try {
        if (location){
          try {
            response = await API.post(config.API_NAME, "/employer/searches/shortlist/getHidden", {
              body: {}
            });
            if (response && response.success){
              dispatch({ type: FETCH_SEARCH_SHORTLIST, data: response.data, viewed: response.viewed, globalViewedCandidates: response.globalViewedCandidates, globalUnlockedCandidates: response.globalUnlockedCandidates });
            }
            else{
              dispatch({ type: FETCH_SEARCH_SHORTLIST, data: false, viewed: [], globalViewedCandidates: {}, globalUnlockedCandidates: {} });
            }
          } catch (e) {
            logger.error("fetchHiddenTalent shortlist():", e);
            dispatch({ type: FETCH_SEARCH_SHORTLIST, data: false, viewed: [], globalViewedCandidates: {}, globalUnlockedCandidates: {} });
          }

          const { jobs } = await API.post(config.API_NAME, "/jobs/query/hidden_talent", {
            body: {
              location: location.location_full.includes("Greater London") ? "London" : location.original_location,
              postcode: location.location_full.split(",")[2].replace(" ", ""),
              lat: location.lat,
              lon: location.lon,
            }
          });
        if (jobs) {
          await dispatch({ type: FETCH_SEARCH_CANDIDATES, data: {hidden_talent: jobs}, sort:"", request_body:{}, extraRequirements: [], searchShortlist:response ? response.data : []});
          dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: { original_location:location.original_location }, newSearch: true});
        }
        else {
          dispatch({ type: FETCH_SEARCH_CANDIDATES, data: [] });
        }
      }
      else {
        dispatch({ type: FETCH_SEARCH_CANDIDATES, data: false });
      }
    } catch (e) {
      logger.error("fetchHiddenTalent():", e);
      dispatch({ type: FETCH_SEARCH_CANDIDATES, data: false });
    }
  };
};

export function fetchSearchCandidates(selectedSearch){
  return async function (dispatch, getState) {
    const { vacancies: { searchShortlist=[]  } } = getState();
    logger.info("fetchSearchCandidates(): ");
    try {
      if (selectedSearch){
        const isRemote = selectedSearch.location_full === "1234567891234567891" ? true : false
        const request_body = {
          job_title: selectedSearch.job_title,
          location: isRemote ? selectedSearch.location_full : selectedSearch.location_full.includes("Greater London") ? "London" : selectedSearch.original_location,
          postcode: isRemote ? "" : selectedSearch.location_full.split(",")[2].replace(" ", ""),
          lat: selectedSearch.lat,
          lon: selectedSearch.lon,
          occupation: selectedSearch.low_occupation,
          skills: selectedSearch.extraRequirements || [],
          required_skills: selectedSearch.skills_required || []
        }

        // NO ENDPOINT YET
        const { jobs } = await API.post(config.API_NAME, "/jobs/query/candidate_search_monster", {
          body: request_body
        });
        if (jobs) {
          //TODO presumable need to search for statues & deleted users too
          let sort = selectedSearch.sort
          await dispatch({ type: FETCH_SEARCH_CANDIDATES, data: jobs, sort:sort, request_body:request_body, extraRequirements: selectedSearch.extraRequirements, searchShortlist:searchShortlist});
          dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: selectedSearch, newSearch: true});
        }
        else {
          dispatch({ type: FETCH_SEARCH_CANDIDATES, data: [] });
        }
      }
      else {
        dispatch({ type: FETCH_SEARCH_CANDIDATES, data: false });
      }
    } catch (e) {
    logger.error("fetchSearchCandidates():", e);
    dispatch({ type: FETCH_SEARCH_CANDIDATES, data: false });
  }
  };
};

export const favouriteCandidate = (candidate, selectedSearch, isFavourited) => {
  return async function (dispatch, getState) {
    logger.info("favouriteCandidate(): ");
    const { vacancies: { searchShortlist=[], searchCandidates  } } = getState();
    try {
      let response = await API.post(config.API_NAME, "/employer/searches/shortlist/put", {
        body: {
          candidate: candidate,
          search: selectedSearch,
          isFavourited: isFavourited,
        }
      });
      if (response && response.success){
        dispatch({ type: UPDATE_SEARCH_SHORTLIST, candidate: response.data, remove: response.remove, shortlist:searchShortlist, searchCandidates:searchCandidates });          
      }
    } catch (e) {
      logger.error("favouriteCandidate():", e);
    }
  };
};


export const viewCandidate = (userId)  => {
  return async function (dispatch, getState) {
    const { vacancies: { viewedCandidates=[] } } = getState();
    try {
      if (!viewedCandidates.includes(userId)){
        const data = API.post(config.API_NAME, "/employer/job/user/view", {
          body: {
            candidateID:  userId,
          } }
        )
        dispatch({ type: UPDATE_VIEWED_CANDIDATES, newView: userId});
      }
      
    } catch (e) {
      logger.error("viewCandidate():", e);
    }
  };
};

export const fetchShortlistCandidates = (selectedSearch) => { // also gets viewed candidates
  return async dispatch => {
    try {
      let response = await API.post(config.API_NAME, "/employer/searches/shortlist/get", {
        body: selectedSearch
      });
      if (response && response.success){
        dispatch({ type: FETCH_SEARCH_SHORTLIST, data: response.data, viewed: response.viewed, globalViewedCandidates: response.globalViewedCandidates, globalUnlockedCandidates: response.globalUnlockedCandidates});
      }
      else{
        dispatch({ type: FETCH_SEARCH_SHORTLIST, data: false, viewed: [], globalViewedCandidates: {}, globalUnlockedCandidates: {}  });
      }
    } catch (e) {
      logger.error("unlockCandidate():", e);
      dispatch({ type: FETCH_SEARCH_SHORTLIST, data: false, viewed: [], globalViewedCandidates: {}, globalUnlockedCandidates: {} });
    }
  };
};

export const unlockCandidate = (candidate, selectedSearch, source, newVersion) => {
  return async function (dispatch, getState) {
    logger.info("unlockCandidate(): ");
    const { vacancies: { searchShortlist=[], searchCandidates  } } = getState();
    try {
      if(newVersion){
        let response = await API.post(config.API_NAME, "/employer/vacancies/shortlist/unlock", {
          body: {
            candidate: candidate,
            vacancy: selectedSearch
          }
        });
        if (response && response.success){
          if (response.data){
            await dispatch(findAllStatuses(selectedSearch.searchOnly ? selectedSearch.sort : selectedSearch.job_id))
            await dispatch(fetchDetailedCandidate(candidate.userID, selectedSearch, source === "viewVacancy" ? true : false, response.data))
            return false
          }
          else{
            return response.message
          }
        }
        else{
          return "An error has occurred"
        }
      }
      else{
        let response = await API.post(config.API_NAME, "/employer/searches/shortlist/unlock", {
          body: {
            candidate: candidate,
            search: selectedSearch
          }
        });
        if (response && response.success){
          if (response.data){
            await dispatch({ type: UPDATE_SEARCH_SHORTLIST, candidate: response.data, shortlist:searchShortlist, searchCandidates:searchCandidates, unlocked:true });
            if (source === "reviewSearchCandidates"){
              dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: selectedSearch});
            }
            return false
          }
          else{
            return response.message
          }
        }
        else{
          return "An error has occurred"
        }
      }
    } catch (e) {
      logger.error("unlockCandidate():", e);
      return e
    }
  };
};


export const transferCandidate = (candidate, selectedSearch, source) => {
  return async function (dispatch, getState) {
    logger.info("transferCandidate(): ");
    const { vacancies: { searchShortlist=[], searchCandidates  } } = getState();
    try {

      let response = await API.post(config.API_NAME, "/employer/searches/shortlist/transfer", {
        body: {
          candidate: candidate,
          newSearch: selectedSearch
        }
      });
      if (response && response.success){
        if (response.data){
          if (source === "reviewSearchCandidates"){
            await dispatch({ type: UPDATE_SEARCH_SHORTLIST, candidate: response.data, remove: "absolute", shortlist:searchShortlist, searchCandidates:searchCandidates});          
            dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: selectedSearch});
          }
          else if(source === "localTalentReview"){
            dispatch({ type: UPDATE_SEARCH_SHORTLIST, candidate: response.data, remove: false, shortlist:searchShortlist, searchCandidates:searchCandidates});          
            dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: selectedSearch});
          }
          else{
            dispatch({ type: UPDATE_SEARCH_SHORTLIST, candidate: response.data, remove: true, shortlist:searchShortlist, searchCandidates:searchCandidates});          
          }
        }
        else{
          return response.message
        }
      }
      else{
        return "An error has occurred"
      }
    } catch (e) {
      logger.error("transferCandidate():", e);
      return e
    }
  };
};


export const getCVLink = (candidate) => {
  return async function (dispatch) {
    logger.info("getCVLink(): ");
    try {
        const genResponse = await API.post(
          config.API_NAME,
          "/pdf-service/monster-pdf",
        {
          body: { id: candidate.free_resume || candidate.talentType === "Premium External Profiles" ? candidate.userID : candidate.sort, freeVersion: candidate.free_resume },
        },
          { timeout: 1200000 }
        );
        if (genResponse && genResponse.success){
          const s3Url = await Storage.get(`monsterpdfs/${candidate.free_resume || candidate.talentType === "Premium External Profiles" ? candidate.userID : candidate.sort}.pdf`,
          {
            level: "private",
          }
          );
          const blob = await fetch(s3Url, { method: "get" });
          return {success: true, data: await blob.blob()}
        }
      }
    catch (e) {
      logger.error("unlockCandidate():", e);
    }
    return {success: false}
  };
};

export const updateSearchFilters = (search, filterObject) => {
  return async function (dispatch) {
    logger.info("updateSearchFilters(): ");
    dispatch({ type: FILTER_SEARCH_CANDIDATES, selectedSearch: search, newFilters: filterObject, filterChange:true});
  };
};

export const fetchBotTemplates = () => {
return async function(dispatch) {
  logger.info("fetchTemplates(): ");
  try {
    let { data } = await API.post(config.API_NAME, "/employer/searches/getTemplates", {
      body: {}
    });
    if (data){
      dispatch({ type: FETCH_BOT_TEMPLATE, baseTemplate: data.baseTemplate, toggleQuestions: data.toggleQuestions, userTemplates: data.userTemplates, start_text: data.start_text, end_text: data.end_text, end_text_escape: data.end_text_escape, emailMain: data.emailMain, emailPromotional: data.emailPromotional});
    }
  } catch (e) {
    logger.error("fetchTemplates():", e);
  }
};
}


export const submitTemplateItem = (newTemplate, isEdit) => {
  return async function (dispatch) {
    logger.info("submitTemplateItem(): ");
    try {
      const data = await API.post(config.API_NAME, "/employer/createTemplate", {
        body: {
          ...newTemplate, 
          isEdit: isEdit
        }
      });
      if (data && data.success){
        if (isEdit){
          dispatch({ type: EDIT_TEMPLATE, data: data.data });
        }
        else{
          dispatch({ type: ADD_TEMPLATE, data: data.data });
        }
        return { success: true };
      }
      else{
        return { success: false };
      }
    } catch (e) {
      logger.error("submitTemplateItem():", e);
    }
  }
}

export const deleteTemplate= template => {
  return async (dispatch, getState) => {
    try {
      const { vacancies: { searches=[]  } } = getState();

      for (const search of searches){
        if(search.templateSort && search.templateSort == template.sort){
          return { success: false, message: "Delete template failed.  Template in use by active search" };
        }
      }

      const data = await API.post(config.API_NAME, "/employer/deleteTemplate", {
        body: template
      });
      if (data && data.success){
        dispatch({ type: REMOVE_TEMPLATE, data: template });
        return { success: true };
      }
      else{
        return { success: false };
      }
    } catch (e) {
      throw e;
    }
  };
};

export const assignTemplateScript = (search, template) => {
  return async function (dispatch) {
    logger.info("assignTemplateScript(): ");
    try {
      const data = await API.post(config.API_NAME, "/employer/searches/assignTemplate", {
        body: {
          ...search, 
          templateName: template.title,
          templateSort:template.sort
        }
      })
      if (data && data.success){
        dispatch({ type: EDIT_VACANCY, data: data.data });
        return { success: true };
      }
      else{
        return { success: false };
      }
    } catch (e) {
      throw e;
    }
  }
}

export const autoComplete = (text) => {
  return async function (dispatch) {
    logger.info("autoComplete(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/occupation", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/fallbackoccupation", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      
      throw e;
    }
    return [];
  }
}


export const autoCompleteV2 = (text) => {
  return async function (dispatch) {
    logger.info("occupationAutoComplete(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/occupationAutoComplete", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/occupationAutoCompleteFallback", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      throw e;
    }
    return {
      directMatches: [],
      closeMatches: []
    };
  }
}


export const autoCompleteV2Skill = (text) => {
  return async function (dispatch) {
    logger.info("skillAutoComplete(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/skillAutoComplete", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/skillAutoCompleteFallback", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      throw e;
    }
    return {
      directMatches: [],
      closeMatches: []
    };
  }
}

export const autoCompleteSkill = (text) => {
  return async function (dispatch) {
    logger.info("autoCompleteSkill(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/skill", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/fallbackskill", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return data.response
      }
    } catch (e) {
      
      throw e;
    }
    return [];
  }
}

export const autoCompleteLocation = (text) => {
  return async function (dispatch) {
    logger.info("autoCompleteLocation(): ");
    try {
      const data = await API.post(config.API_NAME, "/nlu/location", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return Array.isArray(data.response) ? data.response : [data.response]
      }
    } catch (e) {
      console.log(e)
    }
    try {
      const data = await API.post(config.API_NAME, "/nlu/fallbacklocation", {
        body: {
          text: text, 
          dualType: false
        }
      })
      if (data && data.response){
        return Array.isArray(data.response) ? data.response : [data.response]
      }
    } catch (e) {
      throw e;
    }
    return [];
  }
}

//pre qual contact
export const contactCandidate = (candidate, selectedSearch, source, newVersion) => {
  return async function (dispatch, getState) {
    logger.info("contactCandidate(): ");
    const { vacancies: { searchShortlist=[], searchCandidates  } } = getState();
    const isInternalCandidate = ["Community CV's", "Premium External Profiles"].includes(candidate.talentType) ? false : true
    try {

      let response = await API.post(config.API_NAME, "/employer/searches/shortlist/contact", {
        body: {
          candidate: candidate,
          search: selectedSearch,
          isVacancy: selectedSearch.searchOnly ? false : true,
          isFreeCandidate: candidate.free_resume ? true : false,
          isInternalCandidate: isInternalCandidate,
        }
      });
      if (response && response.success){
        if (response.data){
          await dispatch(findAllStatuses(selectedSearch.searchOnly ? selectedSearch.sort : selectedSearch.job_id))
          await dispatch(fetchDetailedCandidate(candidate.userID, selectedSearch, source === "viewVacancy" ? true : false, isInternalCandidate ? false : response.data))
          return false
        }
        else{
          return response.message
        }
      }
      else{
        return "An error has occurred"
      }
    } catch (e) {
      logger.error("contactCandidate():", e);
      return e
    }
  };
};

export const setColumnViewPrefs = (view, values) => {
  return async function (dispatch) {
    logger.info("assignTemplateScript(): ");
    try {
      const data = await API.post(config.API_NAME, "/employer/vacancy/viewprefs", {
        body: {
          view: view,
          values: values,
        }
      })
    } catch (e) {
      throw e;
    }
  }
}

export const getColumnViewPrefs = () => {
  return async function (dispatch) {
    logger.info("getColumnViewPrefs(): ");
    try {
      const data = await API.get(config.API_NAME, "/employer/vacancy/viewprefs", {})
      if (data){
        await dispatch({ type: GET_VIEW_PREFS, data: data});
        return data
      }
    } catch (e) {
      throw e;
    }
  }
}

// /payments/checkout/monstercredits
export const bundleCheckout = (creditOption) => {
  return async (dispatch) => {
    try {
      const checkoutResponse = await API.post(config.API_NAME, "/user/bundleCheckout", {
        body: {
          credits: creditOption
        }
      });
      if (checkoutResponse){
        if(checkoutResponse.success && checkoutResponse.session){
          return {url : checkoutResponse.session.url}
        }
        else if(checkoutResponse.error){
          return {error : checkoutResponse.session.url}
        }
      }
    } catch (e) {
      logger.error("bundleCheckout():", e);
    }
    return {error : "Unknown Error"}
  }
}