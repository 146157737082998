import { Avatar, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { bindActionCreators } from "redux";
import { fetchCandidateDetails } from "../../actions/candidates_actions";
import { CandidateHeader } from "../Candidates/CandidateSimpleDetails";
import { CandidateEngagement } from "../Candidates/CandidateSimpleDetails/CandidateEngagement";
import { CandidateSummaryView } from "../Candidates/CandidateSimpleDetails/CandidateSummaryView";
import { OtherAssessments } from "../Candidates/CandidateSimpleDetails/OtherAssessments";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class Print extends Component {
  componentDidMount = async () => {
    //await this.props.fetchCandidates();
  };

  componentDidUpdate = prevProps => {};
  render() {
    const {
      classes,
      candidates: { selectedCandidateData },
      portaldetails: { missions }
    } = this.props;
    if (!selectedCandidateData) {
      return <Redirect to="/portal/candidates" />;
    }

    let sortedmissions = [];
    _.each(missions, item => {
      //GET SCORES

      var newItem = {
        ...item
      };

      newItem.score =
        selectedCandidateData.latestAssessmentResult.results[
          item.dimension
        ].percentage;

      //GET STATUS
      if (selectedCandidateData.missionsAnalytics) {
        _.each(selectedCandidateData.missionsAnalytics, mission => {
          if (mission.missionId === item.id) {
            if (
              newItem.status !== "Completed" &&
              mission.event_type === "missionStart"
            ) {
              newItem.status = "Started";
            }

            if (mission.event_type === "missionAssumedComplete") {
              newItem.status = "Completed";
            }
          }
        });
      }
      sortedmissions.push(newItem);
    });

    sortedmissions = _.sortBy(sortedmissions, ["score"]);
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.props.history.goBack()}
        >
          Back
        </Button>
        <ReactToPrint
          onBeforeGetContent={() =>
            new Promise(resolve => {
              this.setState({ isOnPrintMode: true });
              setTimeout(() => {
                resolve();
              }, 2000);
            })
          }
          onAfterPrint={() => this.setState({ isOnPrintMode: false })}
          content={() => this.componentRef}
          trigger={() => (
            <Button
              color="primary"
              variant="contained"
              style={{ margin: "1em" }}
            >
              Print
            </Button>
          )}
        />

        <div className={classes.root} ref={el => (this.componentRef = el)}>
          <Grid container direction="column">
            <Grid item xs>
              <Avatar src={"/images/logo_gray.png"} className="show-on-print" />
            </Grid>
            <Grid item xs>
              <CandidateHeader
                candidate={selectedCandidateData}
                user={selectedCandidateData}
                triggerReassessment={() => null}
              />
            </Grid>
            <Grid item xs>
              <CandidateSummaryView
                isOnPrintMode
                candidate={selectedCandidateData}
                user={selectedCandidateData}
                triggerReassessment={() => null}
                groupSkillsBackpack
                rewards={this.props.rewards}
              />
            </Grid>
            <Grid item xs>
              <CandidateEngagement
                missions={sortedmissions}
                candidate={selectedCandidateData}
                keyworkerId={
                  this.props.auth.role === "org-admin"
                    ? this.props.portaldetails.currentKeyWorker
                    : this.props.portaldetails.user.userID
                }
              />
            </Grid>
            <Grid item xs>
              <OtherAssessments isOnPrintMode />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchCandidateDetails }, dispatch),
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Print)));
