import { Logger } from "aws-amplify";
import {
  EMPTY_CANDIDATES,
  EMPTY_CANDIDATE_DETAILS,
  FETCH_CANDIDATES,
  FETCH_CANDIDATE_BACKPACK,
  FETCH_CANDIDATE_DETAILS,
  FETCH_CANDIDATE_ENGAGEMENTS,
  FETCH_CANDIDATE_JOB_MATCHES,
  FETCH_CANDIDATE_MATCHES,
  FETCH_CANDIDATE_SKILLS_INFO,
  FETCH_ALL_OCCUPATIONS,
  SET_NEW_SKILLS
} from "../actions/types";

const logger = new Logger("CANDIDATES.REDUCER", "INFO");
const _ = require("lodash");

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_CANDIDATES: {
      var candidates = [];
      var candidatesAll = [];

      if (!action.candidates) {
        return {
          ...state,
          candidates: null,
        };
      }
      action.candidates.forEach((item) => {
        // we need this for admin
        item.UserStatus = item.Cognito_UserStatus;
        item.Enabled = item.Cognito_Enabled;
        item.Username = item.UserID;
        item.formattedInviteDate = (item.createdAt.toString().length === 13 ) ? new Date(item.createdAt).toLocaleDateString() : new Date(item.createdAt * 1000).toLocaleDateString();
        item.formattedAccessedDate = item.lastAccessedAt ? new Date(item.lastAccessedAt).toLocaleDateString() : "N/A" ;
        item.formattedCompletedDate = item.latestAssessmentCreatedAt ?  new Date(item.latestAssessmentCreatedAt).toLocaleDateString() : "N/A";
        item.flag = item.UserStatus !== "CONFIRMED" ? "Not Logged In" : (!item.assessmentCompleted || !item.assessmentVersion) ? "Assessments/Re-assessments not completed" : "Assessments completed"
        candidatesAll.push(item);

        if (!item.isDisabled) {
          //filtered by status
          candidates.push(item);
        }
      });

      const sortOrder = ["Assessments completed", "Assessments/Re-assessments not completed", "Not Logged In"]
      const returnProps = {
        ...state,
        candidates: candidates.sort((a, b) =>
          b.createdAt - a.createdAt
        ).sort((a, b) =>
          sortOrder.indexOf(a.flag) - sortOrder.indexOf(b.flag)
        ),
        candidatesAll: candidatesAll.sort((a, b) =>
          b.createdAt - a.createdAt
        ).sort((a, b) =>
          sortOrder.indexOf(a.flag) - sortOrder.indexOf(b.flag)
        )
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case EMPTY_CANDIDATES: {
      const returnProps = {
        ...state,
        candidates: action.candidates,
        candidatesAll: [],
        selectedCandidateData: null,
      };
      //delete returnProps.selectedCandidateData

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case EMPTY_CANDIDATE_DETAILS: {
      const returnProps = {
        ...state,
        selectedCandidateData: action.selectedCandidate,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }

    case FETCH_CANDIDATE_DETAILS: {
      var selectedCandidate = [];
      if (action.selectedCandidate) {
        selectedCandidate = action.selectedCandidate;
      } else {
        const returnProps = {
          ...state,
        };
        // return with an empty one
        delete returnProps.selectedCandidateData;
        return returnProps;
      }
      try {
        var item = selectedCandidate.latestAssessmentResult.results;

        var latest = selectedCandidate.latestAssessmentResult.results;

        if (selectedCandidate.assessmentVersion > 1) {
          item = selectedCandidate.assessmentResult.results;
        }

        selectedCandidate.radarData = [
          {
            name: "Commitment",
            latest: latest.COMM.percentage,
            first: item.COMM.percentage,
          },
          {
            name: "Responsiveness",
            latest: latest.RTF.percentage,
            first: item.RTF.percentage,
          },
          {
            name: "Adaptability",
            latest: latest.ADY.percentage,
            first: item.ADY.percentage,
          },
          {
            name: "Complexity",
            latest: latest.SPC.percentage,
            first: item.SPC.percentage,
          },
          {
            name: "Learning",
            latest: latest.RLS.percentage,
            first: latest.RLS.percentage,
          },
          {
            name: "Speed",
            latest: latest.DMA.percentage,
            first: item.DMA.percentage,
          },
          {
            name: "Confidence",
            latest: latest.CONF.percentage,
            first: item.CONF.percentage,
          },
          {
            name: "Control",
            latest: latest.CONT.percentage,
            first: item.CONT.percentage,
          },
          {
            name: "Accuracy",
            latest: latest.PSA.percentage,
            first: item.PSA.percentage,
          },
        ];
        selectedCandidate.ACdata = [
          {
            name: "Complexity",
            latest: latest.SPC.percentage,
            first: item.SPC.percentage,
          },
          {
            name: "Speed",
            latest: latest.DMA.percentage,
            first: item.DMA.percentage,
          },
          {
            name: "Accuracy",
            latest: latest.PSA.percentage,
            first: item.PSA.percentage,
          },
        ];
        selectedCandidate.LAdata = [
          {
            name: "Responsiveness",
            latest: latest.RTF.percentage,
            first: item.RTF.percentage,
          },
          {
            name: "Adaptability",
            latest: latest.ADY.percentage,
            first: item.ADY.percentage,
          },
          {
            name: "Learning",
            latest: latest.RLS.percentage,
            first: item.RLS.percentage,
          },
        ];
        selectedCandidate.REdata = [
          {
            name: "Control",
            latest: latest.CONT.percentage,
            first: item.CONT.percentage,
          },
          {
            name: "Commitment",
            latest: latest.COMM.percentage,
            first: item.COMM.percentage,
          },
          {
            name: "Confidence",
            latest: latest.CONF.percentage,
            first: item.CONF.percentage,
          },
        ];
      } catch (e) {}

      Object.values(selectedCandidate.interventions || {}).forEach(
        (intervention) => {
          intervention.type = !intervention.type ? 2 : intervention.type;
        }
      );
      let newSkills_names = []

      if (selectedCandidate.occupationSkills){
        selectedCandidate.occupationSkills.forEach((mainSkill, i) => {
          mainSkill.skills.forEach((s) => {
            newSkills_names.push(s.name.toLowerCase())
          })
        });
        selectedCandidate.skills_names = [...new Set(newSkills_names)]
      } else {
        selectedCandidate.skills_names = false;
      }
      if (!selectedCandidate.occupationBlacklist){
          selectedCandidate.occupationBlacklist = []
      }

      selectedCandidate.candidateStatus = "Status not entered"
      if (selectedCandidate.candidate_status_history && selectedCandidate.candidate_status_history.length > 0){
        selectedCandidate.candidateStatus = selectedCandidate.candidate_status_history[selectedCandidate.candidate_status_history.length -1].status
      }
            
      const returnProps = {
        ...state,
        selectedCandidateData: selectedCandidate,
      };

      logger.debug("action: ", action);
      logger.debug("returning props: ", returnProps);
      return returnProps;
    }
    case FETCH_CANDIDATE_BACKPACK: {
      const selectedCandidateData = state.selectedCandidateData;
      selectedCandidateData.bestBackpack = action.bestBackpack;
      return { ...state, selectedCandidateData };
    }
    case FETCH_CANDIDATE_MATCHES: {
      const selectedCandidateData = state.selectedCandidateData;
      const { type, matches } = action.payload;
      selectedCandidateData[`${type}_matches`] = matches;
      return { ...state, selectedCandidateData };
    }
    case FETCH_CANDIDATE_SKILLS_INFO: {
      const selectedCandidateData = state.selectedCandidateData;
      selectedCandidateData.skillsInfo = action.skillsInfo;
      return { ...state, selectedCandidateData };
    }
    case FETCH_CANDIDATE_ENGAGEMENTS: {
      const selectedCandidateData = state.selectedCandidateData;

      selectedCandidateData.engagements = action.engagements;
      
      // need to filter out resources that dont have a title or a link.
      let resources = [];
      _.each(action.resourceViewed, (resource) => {
        if (resource.link && resource.title) {
          resources.push(resource);
        }
      });

      selectedCandidateData.resourceViewed = resources;
      selectedCandidateData.coursesViewed = action.coursesViewed

      selectedCandidateData.jobsSaved = action.jobsSaved;
      selectedCandidateData.jobsApplied = action.jobsApplied;
      selectedCandidateData.jobsViewed = action.jobsViewed;

      selectedCandidateData.qualsSaved = action.qualsSaved;
      selectedCandidateData.qualsApplied = action.qualsApplied;
      selectedCandidateData.qualsViewed = action.qualsViewed;

      return { ...state, selectedCandidateData };
    }
    case FETCH_CANDIDATE_JOB_MATCHES: {
      const selectedCandidateData = state.selectedCandidateData;
      const { normalJobMatches, techJobMatches } = action.payload;
      selectedCandidateData.normalJobMatches = normalJobMatches;
      selectedCandidateData.techJobMatches = techJobMatches;
      return { ...state, selectedCandidateData };
    }
    case FETCH_ALL_OCCUPATIONS: {
      let jobcat = action.new_jobs_cats;
      let payload = {};
      if (jobcat.jobs){
        let { desired_occupations,
          interest_occupations,
          past_occupations,
          skills_backpack_occupations,
          styles_occupations
        } = jobcat.jobs;
        payload = {
          desired_occupations,
          interest_occupations,
          past_occupations,
          skills_backpack_occupations,
          styles_occupations
        }
      }

      return {...state, ...payload}
      
    }
    case SET_NEW_SKILLS: {
      return { ...state, occupationSkills: action.newOccupationSkills };
    }
    
    default:
      return state;
  }
}
